<!--
 * @Author: cyy
 * @version: 
 * @Date: 2022-09-01 16:12:35
 * @LastEditTime: 2022-09-02 14:59:53
 * @LastEditors: cyy
 * @Description: 图文-弹窗
 * @FilePath: \teacherdingding.com\src\components\base\networktypeSelect\graphic\graphicSelect.vue
-->
<template>
  <div class="graphicSelect">
    <el-dialog
      title="选择图文"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="false"
      :destroy-on-close="true"
      @close="cancel"
      :append-to-body="true"
    >
      <div class="dialogVisible_bottom">
        <data-contain @listSave="listSave" :ids="ids"></data-contain>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium">取 消</el-button>
        <el-button type="primary" @click="submit" size="medium">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import dataContain from './dataContain'
export default {
  name: 'graphicSelect',

  components: {
    dataContain,
  },

  props: {
    ids: {
      type: Array,
      default: () => [],
    },
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {}
  },

  methods: {
    submit() {
      this.$emit('complete', this.list)
    },

    listSave(val) {
      this.list = val
    },

    cancel() {
      // 取消后回复选择数据原样
      this.$emit('update:dialogstatus', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.graphicSelect {
}
</style>
