<template>
  <header class="header">
    <div class="content">
      <div class="logo" v-if="jgInfo.jg_logo">
        <img :src="jgInfo.jg_logo" />
      </div>
      <div class="jigou-name">
        {{ jgInfo.title }}
      </div>
      <div class="header-right">
        <i class="focus code-icon"></i>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: 'courseIndexHeader',

  props: {
    jgInfo: Object,
  },

  data() {
    return {
      code: '',
      isShowFocusConfirm: false,
      jigouNameFlag: false,
    }
  },

  methods: {
    // 关注弹窗
    toggleFocusConfirm() {
      this.isShowFocusConfirm = !this.isShowFocusConfirm
    },
  },
}
</script>
<style lang="scss" scoped>
.header {
  height: 2.5rem;
  position: relative;
  background-color: #fff;
  .content {
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;
    .logo {
      width: 1.5rem;
      height: 1.5rem;
      margin-left: 0.7rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 2px;
      }
    }
    .jigou-name {
      font-size: 0.8rem;
      font-weight: 600;
      line-height: 1.1rem;
      margin-left: 0.5rem;
      color: rgba(51, 51, 51, 1);
    }
    .header-right {
      position: absolute;
      right: 0.7rem;
      top: 50%;
      -webkit-display: flex;
      display: flex;
      align-items: center;
      font-size: 0;
      transform: translateY(-50%);
      .focus {
        display: inline-block;
        vertical-align: middle;
      }
      .focus {
        width: 2.7rem;
        height: 1.3rem;
        margin-left: 0.7rem;
        border-radius: 0.1rem;
        box-sizing: border-box;
        border: 1px solid #f39700;
        background-color: transparent;
        .t1 {
          color: rgba(243, 151, 0, 1);
        }
        .t2 {
          font-size: 0.6rem;
          font-weight: bold;
          line-height: 0.85rem;
          color: rgba(243, 151, 0, 1);
        }
      }
      .code-icon {
        display: block;
        width: 1.2rem;
        height: 1rem;
        background: url('~@ass/img/1.4.1/icon_wxgzhbs.svg') no-repeat center;
        background-size: 0.8rem 0.8rem;
        border: none;
      }
    }
  }
}
</style>
