<template>
  <div id="courseSetting">
    <div class="box">
      <!-- 公共模块 -->
      <div class="spacing">
        <div class="font1">模块下方间距</div>
        <div class="setting">
          <el-switch
            v-model="optionlist.spacing_below"
            active-value="1"
            inactive-value="2"
          ></el-switch>
          <div class="font2">间距已开启</div>
        </div>
      </div>

      <!-- 模块标题 -->
      <div class="title">模块标题</div>
      <el-input
        type="text"
        placeholder="请输入模块标题"
        v-model="optionlist.name"
        maxlength="15"
        show-word-limit
        class="mb30"
      ></el-input>

      <!-- 展示方式 -->
      <div class="title">展示方式</div>
      <el-radio-group
        v-model="optionlist.show_way"
        class="mb30"
        size="small"
        style="130px"
      >
        <el-radio label="1">列表</el-radio>
        <el-radio label="2">大图</el-radio>
        <!-- <el-radio label="3">大图</el-radio> -->
      </el-radio-group>

      <!-- 展示内容 -->
      <div class="title">展示内容</div>
      <div class="mb30">
        <!-- 自定义选择作业 -->
        <div class="custom">
          <div class="flex-center">
            <el-button style="width: 140px" @click="addCourse">
              + 选择公开课
            </el-button>
            <span class="ml20 hedselect">
              已选 {{ optionlist.list.length }}
            </span>
          </div>
          <div
            class="course_contian"
            v-for="(i, index) in optionlist.list"
            :key="i.open_class_id"
          >
            <div class="tu">
              <img :src="i.cover_img" alt="" />
            </div>
            <div class="right">
              <div class="name">
                {{ i.name }}
              </div>
              <div class="bottom">
                <div class="coursetype">
                  公开课 |
                  {{
                    i.play_type == 3
                      ? `￥${i.play_price}`
                      : i.play_type == 1
                      ? '免费'
                      : '密码获取'
                  }}
                </div>
                <div class="btnss">
                  <span v-if="index > 0" @click="move(index - 1, index)">
                    上移&nbsp;&nbsp;|&nbsp;&nbsp;
                  </span>
                  <span class="disable" v-else>
                    上移&nbsp;&nbsp;|&nbsp;&nbsp;
                  </span>
                  <span
                    v-if="index < optionlist.list.length - 1"
                    @click="move(index + 1, index)"
                  >
                    下移&nbsp;&nbsp;|&nbsp;&nbsp;
                  </span>
                  <span class="disable" v-else>
                    下移&nbsp;&nbsp;|&nbsp;&nbsp;
                  </span>
                  <span @click="remove(index)">移除</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 排序规则 -->
      <!-- <div v-if="optionlist.show_data_type != 2">
        <div class="title">排序规则</div>
        <el-radio-group v-model="optionlist.sort_way" class="radio2">
          <el-radio label="1">创建时间从新到旧</el-radio>
          <el-radio label="2">创建时间从旧到新</el-radio>
          <el-radio label="3">学习人数从多到少</el-radio>
          <el-radio label="4">学习人数从少到多</el-radio>
          <el-radio label="5">访问人数从多到少</el-radio>
          <el-radio label="6">访问人数从少到多</el-radio>
        </el-radio-group>
      </div> -->
    </div>
    <div class="btns">
      <el-button
        style="width: 79px"
        class="mr20"
        size="medium"
        @click="selectedChange('')"
      >
        取消
      </el-button>
      <el-button
        style="width: 79px"
        size="medium"
        type="primary"
        @click="submit"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'workSetting',

  mixins: [selectdata],

  watch: {
    // 改变指定网校内容
    singleselectcontentval(val) {
      this.optionlist.list.push(...val)
    },

    'optionlist.show_data_type'(val) {
      // 如果切换到初始类型时，数据进行重置
      let list = []
      // 如果切换到初始类型时，数据进行重置
      if (val == this.selectdata.show_data_type) {
        list = _.merge([], this.selectdata.list)
      }
      this.optionlist = _.assign({}, this.optionlist, {
        list,
      })
    },
  },

  computed: {
    ...mapState('netSchoolDecration', ['singleselectcontentval', 'selectdata']),
  },

  methods: {
    ...mapMutations('netSchoolDecration', [
      'selectChange',
      'selectdataChange',
      'selectedChange',
    ]),

    // getCourseData(option) {
    //   return new Promise((resolve) => {
    //     this.$http({
    //       isMultipleSelf: 'all',
    //       url: '/HomeTemplate/workbookList',
    //       data: option,
    //       callback: ({ data }) => {
    //         resolve(data)
    //       },
    //     })
    //   })
    // },

    // 选择公开课
    addCourse() {
      this.selectChange({
        templete: 6,
        title: '选择公开课',
        ids: this.optionlist.list.map((item) => item.open_class_id),
      })
    },

    // 移动
    move(newIndex, oldIndex) {
      const item = this.optionlist.list[newIndex]
      this.optionlist.list.splice(newIndex, 1, this.optionlist.list[oldIndex])
      this.optionlist.list.splice(oldIndex, 1, item)
    },

    // 删除
    remove(index) {
      this.optionlist.list.splice(index, 1)
    },

    init() {
      // 空数据才去获取
      if (
        this.optionlist.list.length > 0 ||
        this.optionlist.show_data_type == 2
      )
        return
      // this.getData()
    },

    // getData() {
    //   const val = _.merge({}, this.optionlist)
    //   val.list = data
    //     this.selectdataChange(val)
    //   return this.getCourseData({
    //     type: val.show_data_type,
    //     set_id: val.set_id,
    //     pagesize: val.show_num,
    //     sort_way: val.sort_way,
    //   }).then((data) => {
    //   })
    // },

    submit() {
      this.optionlist.name = this.optionlist.name.trim()
      // if (this.optionlist.show_data_type == 2) {
      //   this.success()
      // } else {
      //   // this.getData().then(() => {
      //   // })
      // }

      const val = _.merge({}, this.optionlist)
      this.selectdataChange(val)

      this.successPrompt()
    },
  },
}
</script>

<style lang="scss" scoped>
@import './courseStting.scss';
</style>