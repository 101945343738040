<template>
  <div class="hidden">
    <div class="center">
      <img src="~@ass/img/1.4.1/icon_ycmk_bs备份 5@2x.png" alt="" />
      <div class="name">模块已隐藏</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'hidden',
  data() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
.hidden {
  z-index: 3;
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.7);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .center {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      width: auto;
      height: 8px;
    }
    .name {
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}
</style>