<template>
  <div class="icons">
    <ul class="list">
      <li
        v-for="item in icons.list"
        :key="item.navbar_component_icon_id"
        class="lh item"
      >
        <div class="vm">
          <img
            class="icon"
            :style="icons.show_way == 2 ? 'border-radius:50%' : ''"
            :src="item.url"
            alt=""
          />
          <div class="text" :title="item.name">{{ item.name }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'icons',

  props: {
    icons: Object,
  },
}
</script>
<style lang="scss" scoped>
.icons {
  background: #fff;
  .list {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    .item {
      width: 25%;
      font-size: 0;
      text-align: center;
      padding: 0.7rem 0;
      .icon {
        width: 2.3rem;
        margin: 0 auto;
        height: 2.3rem;
        margin-bottom: 0.7rem;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .text {
        width: 78px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
        font-size: 0.65rem;
        font-weight: 400;
        color: #666666;
        min-height: 14px;
      }
    }
  }
}
</style>