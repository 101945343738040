<template>
  <div id="navigated">
    <div
      class="nav_contain"
      v-for="(item, index) in optionlist.list"
      :key="item.t_bottom_id"
    >
      <div class="btnsnav" v-if="item.type != 1">
        <el-button
          type="text"
          @click="upDownMove(index, index - 1)"
          :disabled="index == 1"
          style="width: 50px"
        >
          上移
        </el-button>

        <span class="devision"></span>

        <el-button
          style="width: 50px"
          type="text"
          :disabled="index == optionlist.list.length - 1"
          @click="upDownMove(index, index + 1)"
        >
          下移
        </el-button>
        <span class="devision"></span>

        <el-button
          style="width: 50px"
          type="text"
          :disabled="true"
          v-if="item.type == 5"
        >
          隐藏
        </el-button>
        <template v-else>
          <el-popconfirm
            v-if="item.is_show == 1"
            confirm-button-text="确定"
            cancel-button-text="取消"
            icon="el-icon-info"
            icon-color="red"
            title="是否确认隐藏该模块"
            @confirm="showAndHideen(item.t_bottom_id, 2)"
          >
            <el-button slot="reference" style="width: 50px" type="text">
              隐藏
            </el-button>
          </el-popconfirm>
          <el-button
            v-else
            @click="showAndHideen(item.t_bottom_id, 1)"
            type="text"
            style="width: 50px"
          >
            显示
          </el-button>
        </template>
      </div>
      <div class="contain_nav" :class="{ mt10: item.type == 1 }">
        <div class="tu">
          <img :src="item.icon" alt="" />
          <span class="hovershow">
            更换
            <br />
            图标
          </span>
          <el-dropdown
            trigger="click"
            class="hovershow"
            :class="{ hovershow2: visiblechangeval == item.t_bottom_id }"
            @visible-change="visiblechange($event, item.t_bottom_id)"
          >
            <span class="el-dropdown-link">
              <el-button
                type="text"
                style="
                  color: #fff;
                  line-height: 18px;
                  font-size: 12px;
                  position: absolute;
                  left: 7px;
                  top: -10px;
                "
              >
                更换
                <br />
                图标
              </el-button>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <p @click="selecticonku(item.t_bottom_id)">图标库选择</p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p @click="custom(item.t_bottom_id)">
                  自定义图标（推荐200*200px）
                </p>
              </el-dropdown-item>
              <el-dropdown-item>
                <p @click="recovery(item.type)">恢复默认图标</p>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <!-- <div class="selectoption">
            <p @click="selecticonku(item.t_bottom_id)">图标库选择</p>
            <p @click="custom(item.t_bottom_id)">自定义图标（推荐200*200px）</p>
            <p @click="recovery(item.t_bottom_id)">恢复默认图标</p>
          </div> -->
        </div>
        <el-input
          style="width: calc(100% - 60px)"
          type="text"
          placeholder="请输入内容"
          v-model="item.name"
          maxlength="4"
          :disabled="item.type == 1"
          show-word-limit
        ></el-input>
      </div>
      <div class="describe">
        该模块默认跳转位置为【{{ item.type | gettype }}】
      </div>
    </div>

    <div class="btn">
      <el-button style="width: 79px" class="mr20" @click="cancel" size="medium">
        取消
      </el-button>
      <el-button
        style="width: 79px"
        size="medium"
        type="primary"
        @click="submit"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'navigated',

  mixins: [selectdata],

  data() {
    return {
      visiblechangeval: '',
      optionlist: _.merge({}, this.selectdata),
      //默认图标，恢复图标时使用
      recoveryicon: [
        {
          type: 1,
          icon: 'https://img.dingdingkaike.com/h5/bottom/icon_sy_xz@2x.png',
        },

        {
          type: 2,
          icon: 'https://img.dingdingkaike.com/h5/bottom/icon_tgzx@2x.png',
        },
        {
          type: 3,
          icon: 'https://img.dingdingkaike.com/h5/bottom/icon_hymk@2x.png',
        },

        {
          type: 4,
          icon: 'https://img.dingdingkaike.com/h5/bottom/icon_yg@2x.png',
        },

        {
          type: 5,
          icon: 'https://img.dingdingkaike.com/h5/bottom/icon_wd@2x.png',
        },
      ],
    }
  },

  filters: {
    gettype(val) {
      let text = ''
      switch (Number(val)) {
        case 1:
          text = '网校首页'
          break
        case 2:
          text = '推广中心'
          break
        case 3:
          text = '会员专区'
          break
        case 4:
          text = '已购'
          break
        case 5:
          text = '我的'
          break
      }
      return text
    },
  },

  computed: {
    ...mapState('netSchoolDecration', [
      'selectdata',
      'iconslist',
      'isSelectPicture',
    ]),
  },

  watch: {
    //图标库选择
    iconslist() {
      const index = this.optionlist.list.findIndex(
        (item) => item.t_bottom_id == this.selecticonid
      )
      const val = _.assign({}, this.optionlist.list[index], {
        icon: this.iconslist.url,
      })
      this.optionlist.list.splice(index, 1, val)
    },
    // 改变当前选中修改图像的值
    isSelectPicture() {
      const index = this.optionlist.list.findIndex(
        (item) => item.t_bottom_id == this.selecticonid
      )
      const val = _.assign({}, this.optionlist.list[index], {
        icon: this.isSelectPicture.url,
      })
      this.optionlist.list.splice(index, 1, val)
    },
  },

  methods: {
    ...mapMutations('netSchoolDecration', [
      'changgePicture',
      'selecticon',
      'selectdataChange',
      'selectedChange',
    ]),

    visiblechange(val, val2) {
      if (val) {
        this.visiblechangeval = val2
      } else {
        this.visiblechangeval = ''
      }
    },
    //自定义图标
    custom(val) {
      this.selecticonid = val
      this.changgePicture(true)
    },
    //图标库选择
    selecticonku(val) {
      this.selecticonid = val
      this.selecticon(true)
    },
    //恢复默认图标
    recovery(val) {
      const index = this.optionlist.list.findIndex((item) => item.type == val)

      const index2 = this.recoveryicon.findIndex((item) => item.type == val)

      const val2 = _.assign({}, this.optionlist.list[index], {
        icon: this.recoveryicon[index2].icon,
      })
      this.optionlist.list.splice(index, 1, val2)
    },
    //隐藏 显示
    showAndHideen(val, val2) {
      const index = this.optionlist.list.findIndex(
        (item) => item.t_bottom_id == val
      )
      const val3 = _.assign({}, this.optionlist.list[index], {
        is_show: String(val2),
      })
      this.optionlist.list.splice(index, 1, val3)
    },
    //上移 下移
    upDownMove(index, newIndex) {
      let optionlist = this.optionlist.list
      const oldData = optionlist[newIndex]
      optionlist.splice(newIndex, 1, optionlist[index])
      optionlist.splice(index, 1, oldData)
    },
    //确定
    submit() {
      let val = this.optionlist.list.findIndex((item) => item.name == '')
      if (val > -1) {
        this.$root.prompt('请输入模块名称')
      } else {
        this.success()
      }
    },

    // 取消
    cancel() {
      this.selectedChange('')
    },

    //删除
    confirm(val) {
      this.optionlist.list.splice(val, 1)
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #fff;
}
#navigated {
  overflow: auto;
  padding-bottom: 0;
  height: calc(100% - 79px);
  padding-bottom: 0 !important;

  .nav_contain {
    margin-bottom: 20px;
    background-color: #f5f5f5;
    padding: 10px 10px 20px;
    .btnsnav {
      width: 100%;
      height: 12px;
      font-size: 12px;
      color: #1b9d97;
      line-height: 12px;
      display: flex;
      align-items: center;
      // margin: 0 63%;
      justify-content: flex-end;
      margin-bottom: 13px;
      .devision {
        width: 1px;
        background-color: #1b9d97;
        height: 12px;
      }
    }
    .contain_nav {
      display: flex;
      align-items: center;
      .tu {
        margin-right: 20px;
        cursor: pointer;
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        padding: 6px 11px;
        line-height: 18px;
        font-size: 12px;
        // color: #ffffff;
        background: #fff;
        border-radius: 4px;
        position: relative;
        .hovershow {
          color: #fff;
          position: absolute;
          left: 0px;
          top: 0px;
          height: 40px;
          width: 40px;
          text-align: center;
          padding-top: 4px;
          background-color: #0aa29b;
          box-sizing: border-box;
          display: none;
          border-radius: 4px;
        }
        .hovershow2 {
          display: block;
        }
        .selectoption {
          box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.15);
          display: none;
          position: absolute;
          top: 49px;
          left: 0px;
          width: 230px;
          background-color: #fff;
          font-size: 14px;
          color: #333333;
          line-height: 14px;
          z-index: 100;
          p {
            padding-left: 20px;
            line-height: 40px;
          }
        }
        img {
          width: 18px;
          height: auto;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .tu::after {
        content: '';
        height: 15px;
        width: 50px;
        z-index: 200;
        position: absolute;
        top: 40px;
        left: 1px;
      }
      .tu:hover .hovershow {
        display: block;
      }
      .tu:hover .selectoption {
        display: block;
      }
    }
    .describe {
      font-size: 12px;
      color: #666666;
      line-height: 12px;
      margin-top: 14px;
    }
  }
  .btn {
    width: 100%;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px #f5f5f5 solid;
  }
}
</style>