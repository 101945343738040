<template>
  <div class="teacherSelect">
    <el-dialog
      title="添加老师"
      class="dialogVisible"
      width="850px"
      :visible="dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div slot="title">
        添加老师
        <span class="tip">温馨提示：已添加的老师不在列表展示</span>
      </div>
      <div class="dialogVisible_contain">
        <pagination2
          :allFn="true"
          :option="{
            teacher_ids: ids || [],
          }"
          url="/HomeTemplate/teacherList"
          ref="childDialogBox"
        >
          <template v-slot:default="{ tableData }">
            <div class="tablecontain">
              <el-table
                ref="dialogTable"
                @selection-change="DialogBoxSelectionChange"
                :row-key="getRowkey"
                @row-click="handleRowClick"
                :header-cell-style="{
                  background: 'rgba(245,245,245,1)',
                  color: '#333333',
                }"
                :data="tableData"
                style="width: 100%"
              >
                <!-- <el-table-column label width="30">
                <template slot-scope="scope">
                  <el-radio :label="scope.row.teacher_id" v-model="radioId">
                    &nbsp;
                  </el-radio>
                </template>
              </el-table-column> -->
                <el-table-column
                  :reserve-selection="true"
                  type="selection"
                ></el-table-column>
                <el-table-column label="老师">
                  <template slot-scope="{ row }">
                    <div class="contain_teacher">
                      <div class="tu_teacher">
                        <img :src="row.uphoto" alt="" />
                      </div>
                      <div class="name">{{ row.uname }}</div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="介绍">
                  <template slot-scope="{ row }">
                    {{ row.ujianjie }}
                  </template>
                </el-table-column>
                <!-- <el-table-column label="角色">
                  <template slot-scope="{ row }">
                    <span v-if="row.is_jigouadmin == 1">管理员</span>
                    <span v-else>老师</span>
                  </template>
                </el-table-column> -->
                <el-table-column prop="is_jigouadmin " label="角色">
                  <template slot-scope="scope">
                    <div v-for="item in scope.row.role" :key="item">
                      {{ item }}
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </template>
        </pagination2>
      </div>
      <div class="dialog-footer">
        <el-button type="text" @click="editTeacher">邀请老师</el-button>
        <div>
          <el-button @click="cancel" style="width: 86px" size="medium">
            取 消
          </el-button>
          <el-button
            type="primary"
            @click="submit"
            style="width: 86px"
            size="medium"
          >
            确 定
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedteacher: {}, //被选中的老师
      radioId: '',
    }
  },
  props: {
    dialogstatus: {
      tyep: [Boolean, Array],
      default: false,
    },
    ids: Array,
  },
  methods: {
    handleRowClick(row) {
      this.radioId = row.teacher_id
      this.$refs.dialogTable.toggleRowSelection(row)
    },
    getRowkey(row) {
      return row.teacher_id
    },
    DialogBoxSelectionChange(val) {
      this.selectedteacher = val
    },
    submit() {
      this.$emit('selectedteacher', this.selectedteacher)
      this.cancel()
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('close')
    },
    editTeacher() {
      let url = this.$router.resolve({
        path: '/manageTeacher',
      })
      this.$store.dispatch('open', url.href)
    },
  },
}
</script>

<style lang="scss" scoped>
.teacherSelect {
  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 3px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    background: #aaaaaa;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background: #ededed;
  }
  .tip {
    margin-left: 10px;
    font-size: 12px;
    font-family: MicrosoftYaHei;
    color: #ff3535;
    line-height: 12px;
  }
  .dialogVisible_contain {
    height: 400px;
    overflow: auto;
    .contain_teacher {
      display: flex;
      align-items: center;
      .tu_teacher {
        img {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          margin-right: 10px;
        }
      }
      .name {
        width: calc(100% - 40px);
        font-size: 14px;
        color: #333333;
      }
    }
  }
  .dialog-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
</style>
