<template>
  <div id="advertisement">
    <div class="main" ref="list">
      <!-- 公共模块 -->
      <div class="spacing">
        <div class="font1">模块下方间距</div>
        <div class="setting">
          <el-switch
            v-model="optionlist.spacing_below"
            active-value="1"
            inactive-value="2"
          ></el-switch>
          <div class="font2">间距已开启</div>
        </div>
      </div>
      <div class="advertisement">
        <div
          class="more mb10"
          v-for="(item, index) in optionlist.list"
          :key="item.banner_component_image_id"
        >
          <div class="up_dw_dl">
            <span>
              <el-button
                type="text"
                @click="upDownMove(index, index - 1)"
                :disabled="index == 0"
              >
                上移
              </el-button>
            </span>
            <div class="driver"></div>
            <span>
              <el-button
                type="text"
                :disabled="index == optionlist.list.length - 1"
                @click="upDownMove(index, index + 1)"
              >
                下移
              </el-button>
            </span>
            <div class="driver"></div>
            <span>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                title="是否确定删除该模块"
                @confirm="confirm(index)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </span>
          </div>

          <div class="contain_ad">
            <div
              class="tu"
              @click="changgePicturec(item.banner_component_image_id)"
            >
              <img :src="item.url" alt="" v-if="item.url" />
              <div class="tu_zi" v-else>点击选择图片</div>
              <div class="tu_zi2">
                <p>更换图片</p>
                <p>推荐800*450px</p>
              </div>
            </div>
            <div class="right">
              <div class="ad_font1">跳转模块</div>

              <el-select
                @change="changeTypet($event, item.banner_component_image_id)"
                v-model="item.link_type"
                style="width: 210px"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!-- 自定链接输入框 -->
          <el-input
            v-if="item.link_type == '7'"
            v-model="item.link"
            class="mt20"
            placeholder="请输入跳转链接 https://，不填则不跳转"
          ></el-input>
          <!-- 指定网校内容 -->
          <template
            v-if="
              item.link_type == '3' ||
              item.link_type == '4' ||
              item.link_type == '5' ||
              item.link_type == '6'
            "
          >
            <div class="zhiding" v-if="item.content_id != 0">
              <div class="name">
                [{{ item.content_type | getlinktype }}]
                <span>{{ item.content_name }}</span>
              </div>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                title="是否确定移除该内容"
                @confirm="remove(item.banner_component_image_id)"
              >
                <el-button slot="reference" type="text">移除</el-button>
              </el-popconfirm>
            </div>
            <el-button
              v-else
              type="text"
              @click="zhidingtype(item.banner_component_image_id)"
              style="margin-left: 124px; margin-top: 8px"
            >
              +点击选择内容
            </el-button>
          </template>
          <!-- 课程分类 -->
          <template v-if="item.link_type == '2'">
            <div class="zhiding" v-if="item.content_name">
              <div class="name">{{ item.content_name }}</div>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                title="是否确定移除该内容"
                @confirm="remove(item.banner_component_image_id)"
              >
                <el-button slot="reference" type="text">移除</el-button>
              </el-popconfirm>
            </div>
            <el-button
              v-else
              type="text"
              @click="kechengtypec(item.banner_component_image_id)"
              style="margin-left: 124px; margin-top: 8px"
            >
              +点击选择分类
            </el-button>
          </template>
        </div>
        <div
          class="more"
          @click="optionlist.list.length >= 10 ? '' : addmore()"
          :style="optionlist.list.length >= 10 ? 'cursor: no-drop;' : ''"
        >
          <div class="more_font1">+新增广告图</div>
          <div class="tip">最多可添加10张广告图</div>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button style="width: 79px" class="mr20" @click="cancel" size="medium">
        取消
      </el-button>
      <el-button
        style="width: 79px"
        size="medium"
        type="primary"
        @click="submit"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'

export default {
  mixins: [selectdata],
  data() {
    return {
      selctindex: '', //list中被选中的索引
      selectpicture: '', //当前选中模块的id
      options: [
        {
          value: '1',
          label: '全部课程',
        },
        {
          value: '2',
          label: '课程分类',
        },
        {
          value: '3',
          label: '指定网校内容',
        },
        {
          value: '7',
          label: '自定义链接',
        },
      ],
      value2: [''],
      optionlist: _.merge({}, this.selectdata),
    }
  },
  computed: {
    ...mapState('netSchoolDecration', [
      'isSelectPicture',
      'singleselectcontentval',
      'selectdata',
      'typeselectval',
    ]),
  },

  filters: {
    getlinktype(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '资料'
          break
        case 2:
          text = '会员'
          break
        case 3:
          text = '作业'
          break
        default:
          text = '课程'
      }
      return text
    },
  },

  watch: {
    // 改变当前选中修改图像的值
    isSelectPicture() {
      const index = this.optionlist.list.findIndex(
        (item) => item.banner_component_image_id == this.selectpicture
      )
      const val = _.assign({}, this.optionlist.list[index], {
        url: this.isSelectPicture.url_center_cut,
      })
      this.optionlist.list.splice(index, 1, val)
    },
    //改变指定网校内容
    singleselectcontentval() {
      const index = this.optionlist.list.findIndex(
        (item) => item.banner_component_image_id == this.selectpicture
      )
      //没有选中课程的情况
      if (!this.singleselectcontentval.name) {
        return
      }
      const val = _.assign({}, this.optionlist.list[index], {
        content_id:
          this.singleselectcontentval.type == 1
            ? this.singleselectcontentval.ed_id
            : this.singleselectcontentval.type == 2
            ? this.singleselectcontentval.member_id
            : this.singleselectcontentval.type == 3
            ? this.singleselectcontentval.workbook_id
            : this.singleselectcontentval.course_id,
        content_name: this.singleselectcontentval.name,
        content_type: this.singleselectcontentval.type,
      })
      this.optionlist.list.splice(index, 1, val)
    },
    //改变课程分类的数据
    typeselectval() {
      const index = this.optionlist.list.findIndex(
        (item) => item.banner_component_image_id == this.selectpicture
      )
      const val = _.assign({}, this.optionlist.list[index], {
        content_id: this.typeselectval.set_id,
        content_name: this.typeselectval.set_name,
      })
      this.optionlist.list.splice(index, 1, val)
    },
  },

  methods: {
    ...mapMutations('netSchoolDecration', [
      'changgePicture',
      'kechengtype',
      'selectChange',
      'selectdataChange',
      'selectedChange',
    ]),
    //获取index值
    hadindex() {},
    submit() {
      const val = this.optionlist.list.findIndex((item) => item.url == '')
      if (val > -1 && this.optionlist.list.length > 0) {
        this.$root.prompt({
          msg: '请添加广告图片！',
        })
      } else {
        this.success()
      }
    },
    cancel() {
      this.selectedChange('')
    },
    //移除数据
    remove(val) {
      const index = this.optionlist.list.findIndex(
        (item) => item.banner_component_image_id == val
      )
      const val3 = _.assign({}, this.optionlist.list[index], {
        content_id: '',
        content_name: '',
        content_type: '',
      })
      this.optionlist.list.splice(index, 1, val3)
    },
    //改变类型时，清空 content_id: '', content_name: '', content_type: '',三个数据
    changeTypet(val, val2) {
      const index = this.optionlist.list.findIndex(
        (item) => item.banner_component_image_id == val2
      )
      const val3 = _.assign({}, this.optionlist.list[index], {
        content_id: '',
        content_name: '',
        content_type: '',
      })
      this.optionlist.list.splice(index, 1, val3)
    },
    //删除
    confirm(val) {
      this.optionlist.list.splice(val, 1)
    },
    //上移 下移
    upDownMove(index, newIndex) {
      let optionlist = this.optionlist.list
      const oldData = optionlist[newIndex]
      optionlist.splice(newIndex, 1, optionlist[index])
      optionlist.splice(index, 1, oldData)
    },
    //更换图片
    changgePicturec(val) {
      this.selectpicture = val
      this.changgePicture(true)
    },
    // 课程分类
    kechengtypec(val) {
      this.selectpicture = val
      this.kechengtype(true)
    },
    //指定网校内容
    zhidingtype(val) {
      this.selectpicture = val
      this.selectChange(true)
    },
    // 添加更多图片
    addmore() {
      this.optionlist.list.push({
        banner_component_id: '',
        banner_component_image_id: Math.random(),
        content_id: '',
        image_sort_num: '',
        link: '',
        link_type: '',
        url: '',
        content_name: '',
        content_type: '',
      })
      this.$nextTick(() => {
        this.$refs.list.scrollTop = this.$refs.list.scrollHeight
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #fff;
}
#advertisement {
  overflow: auto;
  height: 100%;
  .main {
    overflow: auto;
    height: calc(100% - 79px);
  }
  .spacing {
    margin-bottom: 20px;
    .font1 {
      font-size: 14px;
      color: #333333;
      margin-bottom: 20px;
    }
    .setting {
      display: flex;

      .font2 {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-left: 12px;
      }
    }
  }
  .up_dw_dl {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    color: #1b9d97;
    .driver {
      width: 1px;
      height: 12px;
      background-color: #1b9d97;
      margin: 0 10px;
    }
    span {
      cursor: pointer;
    }
  }
  .zhiding {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .name {
      width: calc(100% - 48px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      color: #333333;
      margin-right: 20px;
    }
    .update {
      cursor: pointer;
      font-size: 14px;
      color: #1b9d97;
    }
  }
  .advertisement {
    overflow: auto;
    .more {
      width: 100%;
      padding: 10px;
      box-sizing: border-box;
      background: #f5f5f5;
      border-radius: 2px;
      cursor: pointer;

      .more_font1 {
        text-align: center;
        font-size: 14px;
        color: #0aa29b;
        margin: 43px 0 32px;
      }
      .tip {
        font-size: 12px;
        color: #999999;
      }

      .contain_ad {
        display: flex;
        justify-content: space-between;
        .tu {
          position: relative;
          background-color: #e7e7e7;
          width: 124px;
          height: 70px;
          border-radius: 2px;
          img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
          .tu_zi {
            font-size: 14px;
            color: #666666;
            text-align: center;
            margin-top: 28px;
            display: block;
          }
          .tu_zi2 {
            position: absolute;
            top: -16px;
            left: 0;
            z-index: 100;
            margin-top: 16px;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            background-color: #1b9d97;
            display: none;
            line-height: 19px;
            height: 100%;
            width: 100%;
            padding-top: 14px;
            box-sizing: border-box;
          }
        }
        .tu:hover {
          background-color: #1b9d97;
        }
        .tu:hover .tu_zi {
          display: none;
        }
        .tu:hover .tu_zi2 {
          display: block;
        }

        .right {
          .ad_font1 {
            font-size: 14px;
            color: #333333;
            margin-bottom: 14px;
          }
        }
      }
    }
  }
  .btns {
    width: 100%;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px #f5f5f5 solid;
  }
}
</style>