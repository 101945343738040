<template>
  <div class="banner">
    <el-carousel height="195px" class="my-swipe" v-if="banner.list.length > 0">
      <template v-if="isbannershow">
        <el-carousel-item
          v-for="item in banner.list"
          :key="item.banner_component_image_id"
        >
          <div class="nav">
            <img :src="item.url" alt="" />
          </div>
        </el-carousel-item>
      </template>
    </el-carousel>
  </div>
</template>
<script>
export default {
  name: 'banner',
  data() {
    return {
      isbannershow: true,
    }
  },
  props: {
    banner: Object,
  },
  watch: {
    'banner.list': {
      handler() {
        this.isbannershow = false
        setTimeout(() => {
          this.isbannershow = true
        }, 100)
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>
<style lang="scss" scoped>
.banner {
  padding: 0.7rem;
  background: #fff;
  ::v-deep .el-carousel__button {
    height: 6px;
    width: 6px;
    border-radius: 50%;
  }
  .my-swipe {
    overflow: hidden;
    .nav {
      display: block;
      overflow: hidden;
      border-radius: 0.3rem;
      padding-top: 56.25%;
      img {
        width: 100%;
        display: block;
        border-radius: inherit;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // object-fit: contain;
      }
    }
  }
}
</style>