<template>
  <div class="courseItem">
    <div class="title">
      <div class="text">{{ course.name }}</div>
      <div class="more" v-if="course.is_show_more == 1">查看更多</div>
    </div>
    <div class="course-list" v-if="course.list.length > 0">
      <course
        v-for="item in course.list.slice(0, course.show_num)"
        :key="item.course_id"
        :item="item"
        :option="course"
      ></course>
    </div>
  </div>
</template>
<script>
import course from './courseItem2'

export default {
  name: 'courses',

  props: {
    course: Object,
  },

  components: {
    course,
  },
}
</script>
<style lang="scss" scoped>
.courseItem {
  background: #fff;
  padding: 0 0 0.7rem;
  > .title {
    display: flex;
    align-items: center;
    .text {
      flex: 1;
      font-size: 0.8rem;
      font-weight: 600;
      color: #333333;
      position: relative;
      z-index: 3;
      padding: 1rem 0.7rem 0.7rem;
    }
    .more {
      font-size: 0.55rem;
      font-weight: 400;
      color: #999999;
      position: relative;
      padding: 20px 24px 20px 0;
      &:after {
        content: '';
        background: url('~@ass/img/1.4.1/ico_mckdj@2x.png');
        width: 6px;
        height: 9px;
        background-size: 100%;
        background-position: center;
        position: absolute;
        right: 0.7rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .course-list {
    margin-top: -1rem;
    font-size: 0;
  }
}
</style>