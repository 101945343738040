<template>
  <div class="graphic">
    <!-- {{ graphic.list }}{{ graphic.list.length }}{{ graphic.show_way }} -->
    <div class="title">
      <div class="text">{{ graphic.name }}</div>
      <div class="more" v-if="graphic.is_show_more == 1">查看更多</div>
    </div>
    <div class="course-list" v-if="graphic.list.length > 0">
      <div
        v-for="item in graphic.list.slice(0, graphic.show_num)"
        :key="item.graphic_id"
        :class="`course-item${graphic.show_way}`"
      >
        <template v-if="graphic.show_way == 1">
          <div class="cover">
            <img :src="item.cover_img" />
          </div>
          <div class="info">
            <div class="title" style="-webkit-box-orient: vertical">
              {{ item.name }}
            </div>
            <div class="price">
              <span class="pic">
                <template v-if="item.price_type == 1">
                  ￥{{ item.price }}
                </template>
                <template v-if="item.price_type == 2">免费</template>
                <template v-if="item.price_type == 3">密码获取</template>
              </span>
              <span class="old-pic" v-if="item.crossed_price > 0">
                {{ item.crossed_price }}
              </span>
            </div>
          </div>
        </template>
        <template v-if="graphic.show_way == 2 || graphic.show_way == 3">
          <div class="cover">
            <img :src="item.cover_img" />
          </div>
          <div class="info">
            <div class="title" style="-webkit-box-orient: vertical">
              {{ item.name }}
            </div>
            <div class="price">
              <span class="pic">
                <template v-if="item.price_type == 1">
                  ￥{{ item.price }}
                </template>
                <template v-if="item.price_type == 2">免费</template>
                <template v-if="item.price_type == 3">密码获取</template>
              </span>
              <span class="old-pic" v-if="item.crossed_price > 0">
                {{ item.crossed_price }}
              </span>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'graphic',

  props: {
    graphic: Object,
  },
}
</script>
<style lang="scss" scoped>
.graphic {
  background: #fff;
  padding: 0 0 0.7rem;
  > .title {
    display: flex;
    align-items: center;
    .text {
      flex: 1;
      font-size: 0.8rem;
      font-weight: 600;
      color: #333333;
      position: relative;
      z-index: 3;
      padding: 1rem 0.7rem 0.7rem;
    }
    .more {
      font-size: 0.55rem;
      font-weight: 400;
      color: #999999;
      position: relative;
      padding: 20px 24px 20px 0;
      &:after {
        content: '';
        background: url('~@ass/img/1.4.1/ico_mckdj@2x.png');
        width: 6px;
        height: 9px;
        background-size: 100%;
        background-position: center;
        position: absolute;
        right: 0.7rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .course-list {
    margin-top: -1rem;
    font-size: 0;
  }
}
@import './courseItem.scss';
</style>
