<template>
  <!-- 网校装修首页 -->
  <div id="onlineSchoolDecoration">
    <!-- 顶部 -->
    <head-navigation :t_home_id="t_home_id"></head-navigation>
    <div class="main_shcool">
      <!-- 左侧模块添加区域 -->
      <div class="left" :class="{ transFormHidden: !isshow }">
        <modular :isshow="isshow"></modular>
        <div class="close" @click="closeopen" v-if="isshow">
          <i class="el-icon-close"></i>
        </div>
        <div class="open" @click="closeopen" v-else>
          添
          <br />
          加
          <br />
          模
          <br />
          块
        </div>
      </div>
      <!-- 中间网校首页效果展示区域 -->
      <div class="net_school_show">
        <div class="phone-header">
          <img src="~@ass/img/1.4.1/img_wxtb@2x.png" alt="" />
        </div>
        <div
          v-for="(item, index) in modules"
          :key="item.id"
          style="position: relative"
          @click="item.data_type > 0 ? isSelected(item.id) : ''"
          :class="{
            select: selected == item.id && index > 0,
            borderstyle: index > 0,
            mrb10: item.spacing_below == 1,
            maskBorder: item.is_show == 2,
          }"
          class="cp"
        >
          <myheader :jgInfo="item" v-if="item.data_type == 0"></myheader>

          <search :option="item" v-if="item.data_type == 1"></search>

          <banner :banner="item" v-if="item.data_type == 2"></banner>

          <icons :icons="item" v-if="item.data_type == 3"></icons>

          <courseItem :course="item" v-if="item.data_type == 4"></courseItem>

          <electronic
            :electronic="item"
            v-if="item.data_type == 5"
          ></electronic>

          <work :work="item" v-if="item.data_type == 6"></work>

          <classification
            :classification="item"
            v-if="item.data_type == 10"
          ></classification>

          <teacher :teachers="item" v-if="item.data_type == 7"></teacher>

          <schoolInfo :jgInfo="item" v-if="item.data_type == 8"></schoolInfo>

          <navigation :navigate="item" v-if="item.data_type == 9"></navigation>

          <graphic v-if="item.data_type == 11" :graphic="item"></graphic>

          <move
            v-if="selected == item.id && index > 0 && item.data_type != 9"
            :item="item"
            :index="index"
          ></move>
          <hidden v-if="item.is_show == 2"></hidden>
          <!--课程 作业不显示空数据-->
          <empty v-if="item.list && item.list.length == 0"></empty>
        </div>
      </div>
      <!-- 右侧参数设置区域 -->
      <div class="configure" :class="{ show: selected }">
        <!-- @selectChange="selectChange" -->
        <configure></configure>
      </div>
    </div>
    <!-- 轮播图选择网校内容 -->
    <networkschool-Select
      v-if="selectChangestatus"
      :dialogstatus="!!selectChangestatus"
      :title="selectChangestatus.title"
      :template="selectChangestatus.templete"
      :ids="selectChangestatus.ids"
      @selectChangenetworkschool="selectChangenetworkschool"
      @singleselectcontentval="singleselectcontentval"
    ></networkschool-Select>

    <!-- 轮播图选择分类弹窗 -->
    <selectcourse-type
      @typeselectval="typeselectval"
      v-if="selectcourseTypeStatus"
      :default_key="selectcourseTypeStatus.key"
      :dialogstatus="!!selectcourseTypeStatus"
      @close="kechengtype(false)"
    ></selectcourse-type>

    <!-- 图片选择弹窗 locationUpload本地上传触发 选中图片的回调 list  获取选中左侧列表的id group_id-->
    <addpicture
      v-if="addpicturestatus"
      :dialogstatus="addpicturestatus"
      @locationUpload="locationUpload"
      :type="1"
      ref="addpicture"
      @group_id="group_id"
      @list="list"
      @close="changgePicture(false)"
    ></addpicture>
    <!-- 本地上传弹窗 -->
    <upload
      v-if="uploadStart"
      @uploadConfirmClose="uploadConfirmClose"
      @close="uploadConfirmClose"
      :typeselection="1"
      :title="'上传图片(多选)'"
      :uploadType="2"
      :list="group_id1"
    ></upload>
    <!-- 图标库选择 -->
    <iconSelect
      @close="closeicon"
      @complete="complete"
      v-if="selecticonstatus"
      :dialogstatus="selecticonstatus"
    ></iconSelect>

    <!-- 添加老师 -->
    <teacher-select
      v-if="teacherSelectstatus"
      :ids="teacherSelectstatus"
      :dialogstatus="teacherSelectstatus"
      @selectedteacher="selectedteacher"
      @close="teacherSelect(false)"
    ></teacher-select>

    <!-- 选择课程分类 -->
    <selectcourse-type3
      v-if="selectcourseType3status"
      :dialogstatus.sync="selectcourseType3status"
      @typeselectval="typeselectval2"
    ></selectcourse-type3>
  </div>
</template>

<script>
// 选择课程分类
import selectcourseType3 from '@cm/base/selectcourseType3'
import { mapState, mapMutations, mapActions } from 'vuex'
//添加老师弹窗
import teacherSelect from '@cm/base/teacherSelect'
// 头部导航
import headNavigation from './components/headNavigation'
//图标选择弹窗
import iconSelect from '@cm/base/iconSelect'
//本地上传弹窗
import upload from '@cm/base/UploadtextFile'
//图片组件
import addpicture from '@cm/base/addpicture'
//选择课程分类
import selectcourseType from '@cm/base/selectcourseType'
// 选择网校内容
import networkschoolSelect from '@cm/base/networktypeSelect/networkschoolSelect'
//右侧参数的配置
import configure from './components/configure'
//左侧模块添加
import modular from './components/modular'
//底部黑色背景
import schooldecorationBackground from '@/mixin/schooldecorationBackground'
//空状态
import empty from './centerComponents/empty'
//隐藏的样式
import hidden from './centerComponents/hidden'
//移动按钮组件
import move from './centerComponents/move'

// 中间的模板
import myheader from './centerComponents/Header'
import search from './centerComponents/search'
import banner from './centerComponents/banner'
import icons from './centerComponents/icons'
import courseItem from './centerComponents/courseItem'
import electronic from './centerComponents/electronic'
import work from './centerComponents/work'
import classification from './centerComponents/classification'
import graphic from './centerComponents/graphic'

import teacher from './centerComponents/teacher'
import schoolInfo from './centerComponents/schoolInfo'
import navigation from './centerComponents/navigation'

export default {
  mixins: [schooldecorationBackground],
  components: {
    selectcourseType3,
    modular,
    configure,
    networkschoolSelect,
    selectcourseType,
    addpicture,
    upload,
    iconSelect,
    headNavigation,
    teacherSelect,
    // 中间的模板组件
    myheader,
    search,
    banner,
    icons,
    courseItem,
    teacher,
    schoolInfo,
    navigation,
    empty,
    hidden,
    move,
    work,
    electronic,
    classification,
    graphic,
  },
  computed: {
    ...mapState('netSchoolDecration', [
      'changgePicturestatus',
      'kechengtypestatus',
      'selecticonstatus',
      'selectChangestatus',
      'modules',
      'teacherSelectstatus',
      'selected',
      'group_id1',
      'uploadStart',
      'isSelectPicture',
      'selectcourseType3status',
    ]),
    // 获取选择的装修模板id
    t_home_id() {
      return this.$route.query.id
    },
    //添加图片弹窗控制
    addpicturestatus() {
      return this.changgePicturestatus
    },
    //课程分类弹窗
    selectcourseTypeStatus() {
      return this.kechengtypestatus
    },
  },
  created() {
    this.networkSchoolDecration(this.t_home_id)
  },
  data() {
    return {
      drawer: false, //抽屉显示
      //false:展开 true：关闭
      isshow: true,
    }
  },
  methods: {
    ...mapActions('netSchoolDecration', ['networkSchoolDecration']),
    ...mapMutations('netSchoolDecration', [
      'selectcourseType3statuslist',
      'selectedChange',
      'groupid1Change',
      'uploadStartChange',
      'isSelectPicturechange',
      'changgePicture',
      'selectChange',
      'kechengtype',
      'teacherSelect',
      'singleselectcontentvalchange',
      'typeselectlist',
      'selecticon',
      'iconSelected',
      'selectedteacherChange',
    ]),
    //获取老师选择弹窗回调
    selectedteacher(val) {
      this.selectedteacherChange(val)
    },
    // 获取课程分类选择的内容
    typeselectval2(val) {
      this.selectcourseType3statuslist(val)
    },
    //图标导航弹窗中选择的数据
    complete(val) {
      this.iconSelected(val)
    },
    //图标弹窗关闭
    closeicon() {
      this.selecticon(false)
    },
    //获取被选中课程分类的数据
    typeselectval(val) {
      this.typeselectlist(val)
    },
    //自定网校内容选择的数据
    singleselectcontentval(val) {
      this.singleselectcontentvalchange(val)
    },
    //控制指定网校内容的弹窗开关
    selectChangenetworkschool() {
      this.selectChange(false)
    },
    //选中图片的回调
    list(val) {
      this.isSelectPicturechange(val)
    },

    //被选中的样式
    isSelected(val) {
      this.selectedChange(val)
    },

    // 上传弹窗关闭
    uploadConfirmClose() {
      this.$refs.addpicture.renew()
      this.uploadStartChange(false)
    },

    //获取图片上传中的分组id
    group_id(val) {
      this.groupid1Change(val)
      // this.group_id1 = val
    },

    //本地上传弹窗控制
    locationUpload() {
      this.uploadStartChange(true)
    },

    // 轮播图片中指定网校内容的弹窗的弹出
    // selectChange(val) {
    //   this.status = val
    // },

    //左侧列表的开关
    closeopen() {
      this.isshow = !this.isshow
    },
  },
}
</script>

<style lang="scss" scoped>
#onlineSchoolDecoration {
  .borderstyle {
    border: 2px solid #fff;
  }
  .borderstyle:last-child {
    border: 2px solid transparent;
  }
  .borderstyle:hover {
    border: 2px dotted #1b9d97;
  }
  .borderstyle.select {
    border: 2px solid #1b9d97;
  }
  .borderstyle.maskBorder {
    border: 0;
  }
  .main_shcool {
    padding: 58px 415px 0px 200px;
    .phone-header {
      width: 375px;
      img {
        width: 100%;
        display: block;
      }
    }
    .left {
      z-index: 7;
      transition: transform 0.35s ease;
      padding: 10px;
      box-sizing: border-box;
      width: 200px;
      height: calc(100vh - 58px);
      position: fixed;
      left: 0;
      bottom: 0;
      background: #ffffff;
      box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.12);
      transition: transform 0.35s;
      &.transFormHidden {
        transform: translateX(-100%);
      }

      .close {
        cursor: pointer;
        right: -30px;
        z-index: 10;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 30px;
        height: 32px;
        background: #1b9d97;
        border-radius: 0px 4px 4px 0px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
          color: #fff;
        }
      }

      .open {
        position: absolute;
        top: 50%;
        right: -30px;
        transform: translateY(-50%);
        padding: 12px 8px;
        box-sizing: border-box;
        width: 30px;
        height: 100px;
        background: #1b9d97;
        border-radius: 0px 4px 4px 0px;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        line-height: 20px;
        cursor: pointer;
      }
    }

    .net_school_show {
      padding-bottom: 51px;
      width: 375px;
      margin: 0 auto;
      position: relative;
      min-height: calc(100vh - 116px);
      background-color: #f5f5f5;
    }
    .configure {
      z-index: 7;
      position: fixed;
      bottom: 0;
      right: 0;
      width: 415px;
      height: calc(100vh - 58px);
      background-color: #fff;
      transform: translateX(100%);
      transition: transform 0.35s;
      &.show {
        transform: translateX(0);
      }
    }
  }
}
.cp:last-child {
  position: absolute !important;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}
</style>
