<template>
  <div class="teacher">
    <div class="title">{{ teachers.name }}</div>
    <template v-if="teachers.list.length > 0">
      <div class="list">
        <div
          class="item"
          v-for="(item, index) in teachers.list"
          :key="item.team_component_teacher_id"
          @click="curr = index"
        >
          <div class="photo">
            <img
              class="img"
              :class="{ selected: curr === index }"
              :src="item.uphoto"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="detail">
        <div class="name">{{ teacherInfo.uname }}</div>
        <div class="jianjie">
          {{ teacherInfo.ujianjie }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'teacher',
  props: {
    teachers: Object,
  },

  data() {
    return {
      curr: 0,
    }
  },

  watch: {
    teachers: {
      handler() {
        this.curr = 0
      },
      deep: true,
      immediate: true,
    },
  },

  computed: {
    teacherInfo() {
      return this.teachers.list[this.curr]
    },
  },
}
</script>
<style lang="scss" scoped>
.teacher {
  background: #fff;
  padding: 0 0 0.7rem;
  .title {
    font-size: 0.8rem;
    font-weight: 600;
    color: #333333;
    padding: 1rem 0.7rem 0.7rem;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    .item {
      width: 25%;
      margin-bottom: 0.7rem;
      .photo {
        width: 3.8rem;
        height: 3.8rem;
        margin: 0 auto;
        .img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 0.2rem;
          box-sizing: border-box;
          border: 2px solid transparent;
          &.selected {
            border: 2px solid #1b9d97;
          }
        }
      }
    }
  }
  .detail {
    margin-top: 0.3rem;
    padding: 0 0.7rem;
    .name {
      font-size: 0.8rem;
      font-weight: 600;
      color: #333333;
    }
    .jianjie {
      font-size: 0.6rem;
      font-weight: 400;
      color: #888888;
      line-height: 0.9rem;
      margin-top: 0.6rem;
      word-break: break-all;
    }
  }
}
</style>