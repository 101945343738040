<template>
  <div id="classificationSetting">
    <div class="box">
      <!-- 公共模块 -->
      <div class="spacing">
        <div class="font1">模块下方间距</div>
        <div class="setting">
          <el-switch
            v-model="optionlist.spacing_below"
            active-value="1"
            inactive-value="2"
          ></el-switch>
          <div class="font2">间距已开启</div>
        </div>
      </div>

      <!-- 模块标题 -->
      <div class="title">模块标题</div>
      <el-input
        type="text"
        placeholder="请输入模块标题"
        v-model="optionlist.name"
        maxlength="15"
        show-word-limit
        class="mb30"
      ></el-input>

      <!-- 展示方式 -->
      <div class="title">展示方式</div>
      <el-radio-group
        v-model="optionlist.show_way"
        class="mb30"
        size="small"
        style="130px"
      >
        <el-radio label="1">圆角矩形</el-radio>
        <el-radio label="2">圆形</el-radio>
        <!-- <el-radio label="3">大图</el-radio> -->
      </el-radio-group>

      <!-- 展示内容 -->
      <div class="title">展示内容</div>
      <div class="mb30">
        <!-- 自定义选择作业 -->
        <div class="custom">
          <div
            class="course_contian"
            v-for="(i, index) in optionlist.list"
            :key="i.set_id"
          >
            <div class="tu">
              <img :src="i.icon" alt="" />
            </div>
            <div class="name" :title="i.set_name">
              {{ i.set_name }}
            </div>
            <div class="btnss">
              <span v-if="index > 0" @click="move(index - 1, index)">
                上移
                <span class="drvier">|</span>
              </span>
              <span class="disable" v-else>
                上移
                <span class="drvier">|</span>
              </span>
              <span
                v-if="index < optionlist.list.length - 1"
                @click="move(index + 1, index)"
              >
                下移
                <span class="drvier">|</span>
              </span>
              <span class="disable" v-else>
                下移
                <span class="drvier">|</span>
              </span>
              <el-popconfirm
                title="是否确定移除该内容？"
                @confirm="remove(index)"
              >
                <span slot="reference">移除</span>
              </el-popconfirm>
            </div>
          </div>
        </div>
        <div class="addicons" @click="addclass">+选择分类</div>
      </div>
    </div>
    <div class="btns">
      <el-button
        style="width: 79px"
        class="mr20"
        size="medium"
        @click="selectedChange('')"
      >
        取消
      </el-button>
      <el-button
        style="width: 79px"
        size="medium"
        type="primary"
        @click="submit"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'classificationSetting',

  mixins: [selectdata],

  data() {
    return {
      selectvalList: [],
    }
  },

  watch: {
    selectcourseType3statuslist(val) {
      this.optionlist.list.push(...val)
    },
    // 'optionlist.show_data_type'(val) {
    //   // 如果切换到初始类型时，数据进行重置
    //   let list = []
    //   // 如果切换到初始类型时，数据进行重置
    //   if (val == this.selectdata.show_data_type) {
    //     list = _.merge([], this.selectdata.list)
    //   }
    //   this.optionlist = _.assign({}, this.optionlist, {
    //     list,
    //   })
    // },
  },

  computed: {
    ...mapState('netSchoolDecration', [
      'selectdata',
      'selectcourseType3statuslist',
    ]),
  },

  methods: {
    ...mapMutations('netSchoolDecration', [
      'selectChange',
      'selectdataChange',
      'selectcourseType3statusChange',
      'selectedChange',
    ]),

    addclass() {
      if (this.optionlist.list.length > 0) {
        const arr = this.optionlist.list.map((item) => item.set_id)
        this.selectcourseType3statusChange(arr)
      } else {
        this.selectcourseType3statusChange(true)
      }
    },

    // 移动
    move(newIndex, oldIndex) {
      const item = this.optionlist.list[newIndex]
      this.optionlist.list.splice(newIndex, 1, this.optionlist.list[oldIndex])
      this.optionlist.list.splice(oldIndex, 1, item)
    },

    // 删除
    remove(index) {
      this.optionlist.list.splice(index, 1)
    },

    init() {
      // 空数据才去获取
      if (
        this.optionlist.list.length > 0 ||
        this.optionlist.show_data_type == 2
      )
        return
    },

    submit() {
      this.optionlist.name = this.optionlist.name.trim()

      const val = _.merge({}, this.optionlist)
      this.selectdataChange(val)

      this.successPrompt()
    },
  },
}
</script>


<style lang="scss" scoped>
#classificationSetting {
  height: 100%;
  ::-webkit-scrollbar {
    width: 2px;
    height: 8px;
    background-color: #fff;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    // -webkit-box-shadow: inset 0 0 2px rgba(0,0,0,0.3);
    background-color: #fff;
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ccc;
  }

  .box {
    height: calc(100% - 57px);
    overflow: auto;
    position: relative;
    flex: 1;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
      background-color: #fff;
    }
  }
  .addicons {
    margin-top: 10px;
    cursor: pointer;
    padding: 9px 0;
    width: 100%;
    background: #f5f5f5;
    border-radius: 2px;
    font-size: 14px;
    color: #0aa29b;
    text-align: center;
  }
  .spacing {
    margin-bottom: 20px;
    .font1 {
      font-size: 14px;
      color: #333333;
      margin-bottom: 20px;
    }
    .setting {
      display: flex;

      .font2 {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-left: 12px;
      }
    }
  }
  .title {
    margin-bottom: 15px;
    font-size: 14px;
    color: #333333;
  }
  .course_contian {
    height: 40px;
    display: flex;
    align-items: center;
    .tu {
      border-radius: 3px;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
    .name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: calc(100% - 170px);
      font-size: 13px;
      color: #333333;
    }
    .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 19px;
      .coursetype {
        font-size: 12px;
        color: #999999;
      }
    }
  }
  .btnss {
    font-size: 14px;
    color: #1b9d97;
    display: flex;
    align-items: center;
    white-space: nowrap;
    span {
      cursor: pointer;
      &.disable {
        color: #bbb;
        cursor: no-drop;
      }
    }
    .drvier {
      color: #0aa29b;
      margin: 0 10px;
    }
  }
  .btns {
    width: 100%;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px #f5f5f5 solid;
    background-color: #fff;
  }
}
</style>