<template>
  <div id="headNavigation">
    <div class="left">
      <div class="goback" @click="goback">
        <i class="el-icon-arrow-left"></i>
        <div class="backfont">返回后台</div>
      </div>
      <!-- <div class="dirver"></div> -->
      <!-- <div class="select_type">
        <el-select v-model="value" placeholder="请选择" style="width: 100px">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disable"
          ></el-option>
        </el-select>
      </div> -->
    </div>
    <div class="center">
      {{ $route.query.name }}
      <!-- <i class="el-icon-arrow-right"></i> -->
    </div>
    <div class="right">
      <el-button
        type="primary"
        size="medium "
        style="width: 110px"
        @click="save"
      >
        发布
      </el-button>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions, mapMutations } = createNamespacedHelpers(
  'netSchoolDecration'
)

export default {
  data() {
    return {
      options: [
        {
          value: 0,
          label: '网校首页',
        },
        {
          value: 1,
          label: '推广中心（敬请期待）',
          disable: true,
        },
        {
          value: 2,
          label: '会员专区（敬请期待）',
          disable: true,
        },
        {
          value: 3,
          label: '已购（敬请期待）',
          disable: true,
        },
        {
          value: 4,
          label: '我的（敬请期待）',
          disable: true,
        },
      ],
      value: 0,
    }
  },

  props: {
    t_home_id: [Number, String],
  },

  computed: {
    ...mapState(['modules', 'originModules']), // 获取选择的装修模板id
  },

  methods: {
    ...mapActions(['networkSchoolDecration']),
    ...mapMutations(['selectedChange']),

    gotoHome() {
      this.$router.push('/h5School')
    },

    // 返回
    goback() {
      if (
        this.originModules.length > 0 &&
        _.isEqual(this.originModules, this.modules)
      ) {
        this.gotoHome()
      } else {
        this.$confirm('此时离开将丢失已编辑的内容, 是否离开?', '温馨提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.gotoHome()
        })
      }
    },

    // 保存
    // 发布
    save() {
      const data = _.merge([], this.modules)
      const bottom = data.pop()
      this.$confirm(
        '发布前请确认修改的内容已保存，发布后将覆盖之前的网校内容，是否发布？',
        '发布提示',
        {
          confirmButtonText: '确定发布',
          cancelButtonText: '取消',
        }
      )
        .then(() => {
          this.$http({
            url: '/HomeTemplate/preservation',
            data: { data, bottom, t_home_id: this.t_home_id },
            callback: ({ code }) => {
              if (code == 200) {
                this.$root.prompt({
                  type: 'success',
                  msg: '保存成功！',
                })

                this.networkSchoolDecration(this.t_home_id)
                this.selectedChange()
                // this.$router.push('/h5School')
              }
            },
          })
        })
        .catch(() => {})
    },
  },
}
</script>

<style lang="scss" scoped>
#headNavigation {
  position: fixed;
  width: 100%;
  z-index: 999;
  height: 58px;
  background-color: #fff;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
  .left {
    display: flex;

    .goback {
      padding: 21px 20px;
      display: flex;
      cursor: pointer;

      .backfont {
        margin-left: 5px;
        font-size: 16px;
        color: #333333;
      }
    }

    .dirver {
      width: 2px;
      height: 58px;
      background-color: #f5f5f5;
      margin-right: 10px;
    }

    .select_type {
      padding: 10px;

      .el-input__inner {
        border: 0;
      }
    }
  }

  .center {
    height: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
    color: #333333;

    i {
      margin-left: 8px;
    }
  }

  .right {
    margin-right: 20px;

    .cbotton {
      color: #0aa29b;
    }
  }
}
</style>
