<template>
  <div class="searchlan">
    <!-- 公共模块 -->
    <div class="spacing">
      <div class="font1">模块下方间距</div>
      <div class="setting">
        <el-switch
          v-model="optionlist.spacing_below"
          active-value="1"
          inactive-value="2"
        ></el-switch>
        <div class="font2">间距已开启</div>
      </div>
    </div>

    <div class="btns">
      <el-button style="width: 79px" class="mr20" size="medium" @click="cancel">
        取消
      </el-button>
      <el-button
        style="width: 79px"
        size="medium"
        type="primary"
        @click="submit"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'searchlan',

  mixins: [selectdata],

  data() {
    return {
      optionlist: _.merge({}, this.selectdata),
    }
  },

  computed: {
    ...mapState('netSchoolDecration', ['selectdata']),
  },

  methods: {
    ...mapMutations('netSchoolDecration', [
      'selectdataChange',
      'selectedChange',
    ]),

    submit() {
      this.success()
    },

    cancel() {
      this.selectedChange('')
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #fff;
}
.searchlan {
  height: 100%;
  .spacing {
    overflow: auto;
    height: calc(100% - 80px);
    // margin-bottom: 20px;
    .font1 {
      font-size: 14px;
      color: #333333;
      margin-bottom: 20px;
    }
    .setting {
      display: flex;

      .font2 {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-left: 12px;
      }
    }
  }

  .btns {
    width: 100%;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px #f5f5f5 solid;
  }
}
</style>