<template>
  <div class="selectcourseType">
    <el-dialog
      title="添加课程分类"
      class="dialogVisible"
      width="850px"
      :visible="!!dialogstatus"
      @update:visible="v => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="prompt">只可选择一级分类展示到网校</div>
      <div class="input">
        <el-input placeholder="输入名称搜索" v-model="post.name"></el-input>
      </div>
      <pagination2
        :option="post"
        url="/courseSet/getTopCourseSetList"
        :allFn="true"
      >
        <template v-slot:default="{ tableData }">
          <el-table
            @row-click="rowClick"
            ref="table"
            :row-key="getRowkey"
            :data="tableData"
            :header-cell-style="{
              background: 'rgba(245,245,245,1)',
              color: '#333333',
            }"
            :height="350"
            @selection-change="selectionChange"
          >
            <el-table-column
              type="selection"
              :reserve-selection="true"
            ></el-table-column>

            <el-table-column prop="set_name" label="分类名称">
              <template scope="{ row }">
                <div class="category-name">
                  <img class="img" :src="row.icon" alt="" />
                  <div class="name">{{ row.set_name }}</div>
                </div>
              </template>
            </el-table-column>

            <el-table-column
              prop="course_num"
              label="课程数量"
            ></el-table-column>
            <el-table-column
              prop="children_num"
              label="子级分类"
            ></el-table-column>
            <el-table-column
              prop="children_course_num"
              label="子级课程数量"
            ></el-table-column>
          </el-table>
        </template>
      </pagination2>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel" size="medium" style="width: 74px">
          取消
        </el-button>
        <el-button
          type="primary"
          size="medium"
          @click="sure"
          style="width: 74px"
        >
          确定({{ selection.length }})
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  data() {
    return {
      selection: [],
      data: [],
      post: {
        name: '',
        set_ids: Array.isArray(this.dialogstatus) ? this.dialogstatus : [],
      },
    }
  },

  props: {
    dialogstatus: {
      tyep: [Boolean, Array],
      default: false,
    },
    default_key: String,
  },

  methods: {
    ...mapMutations('netSchoolDecration', ['selectcourseType3statusChange']),

    getRowkey(val) {
      return val.set_id
    },

    rowClick(val) {
      this.$refs.table.toggleRowSelection(val)
    },

    sure() {
      this.$emit('typeselectval', this.selection)
      this.cancel()
    },

    cancel() {
      // 取消后回复选择数据原样
      this.selectcourseType3statusChange(false)
    },

    selectionChange(val) {
      this.selection = val
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep .el-table__expand-icon {
  display: none !important;
}
.category-name {
  font-size: 0;
  display: flex;
  align-items: center;
  .img {
    width: 30px;
    height: 30px;
    border-radius: 4px;
  }
  .name {
    font-size: 14px;
    color: #333333;
    margin-left: 10px;
  }
}
::v-deep .dialogVisible {
  .el-dialog__body {
    padding: 0 20px;
  }
}
.prompt {
  position: absolute;
  font-size: 12px;
  color: #ff3535;
  line-height: 16px;
  left: 155px;
  top: 26px;
}
::v-deep .input {
  font-size: 0;
  text-align: right;
  margin-bottom: 20px;
  .el-input {
    width: 215px;
    display: inline-block;
  }
}
</style>
