<template>
  <div id="iconNavigation">
    <div class="main" ref="list">
      <!-- 公共模块 -->
      <div class="spacing">
        <div class="font1">模块下方间距</div>
        <div class="setting">
          <el-switch
            v-model="optionlist.spacing_below"
            active-value="1"
            inactive-value="2"
          ></el-switch>
          <div class="font2">间距已开启</div>
        </div>
      </div>

      <!-- 展示方式 -->
      <div class="title">展示方式</div>
      <el-radio-group
        v-model="optionlist.show_way"
        class="mb30"
        size="small"
        style="130px"
      >
        <el-radio label="1">圆角矩形</el-radio>
        <el-radio label="2">圆形</el-radio>
        <!-- <el-radio label="3">大图</el-radio> -->
      </el-radio-group>

      <div class="icon_setting" ref="icon_setting">
        <div
          class="ico"
          v-for="(i, index) in optionlist.list"
          :key="i.navbar_component_icon_id"
        >
          <div class="up_dw_dl">
            <el-button
              type="text"
              @click="upDownMove(index, index - 1)"
              :disabled="index == 0"
            >
              上移
            </el-button>
            <div class="driver"></div>
            <el-button
              type="text"
              :disabled="index == optionlist.list.length - 1"
              @click="upDownMove(index, index + 1)"
            >
              下移
            </el-button>
            <div class="driver"></div>
            <span>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                title="是否要移除该模块"
                @confirm="confirm(index)"
              >
                <el-button slot="reference" type="text">移除</el-button>
              </el-popconfirm>
            </span>
          </div>
          <div class="ico_contian">
            <div class="tu" :style="i.url ? '' : 'background:#E7E7E7'">
              <img :src="i.url" alt="" v-if="i.url" />
              <span class="noselect" v-else>
                未选
                <br />
                图标
              </span>
              <!-- <el-popover
                placement="bottom"
                width="200"
                trigger="click"
                content=" "
              >
                <div slot="content" class="selectoption">
                  
                 
                </div>
              </el-popover> -->
              <el-dropdown
                trigger="click"
                class="hovershow"
                :class="{
                  hovershow2: visiblechangeval == i.navbar_component_icon_id,
                }"
                @visible-change="
                  visiblechange($event, i.navbar_component_icon_id)
                "
              >
                <span class="el-dropdown-link">
                  <el-button
                    type="text"
                    style="
                      color: #fff;
                      line-height: 18px;
                      font-size: 12px;
                      position: absolute;
                      left: 9px;
                      top: -6px;
                    "
                  >
                    更换
                    <br />
                    图标
                  </el-button>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item>
                    <p @click="selecticonku(i.navbar_component_icon_id)">
                      图标库选择
                    </p>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <p @click="custom(i.navbar_component_icon_id)">
                      自定义图标（推荐200*200px）
                    </p>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>

            <div class="right">
              <div class="r_contian">
                <div class="name">图标名称</div>
                <el-input
                  style="width: 210px"
                  v-model="i.name"
                  placeholder="请输入名称"
                  maxlength="10"
                  show-word-limit
                ></el-input>
              </div>
              <div class="r_contian">
                <div class="name">跳转模块</div>
                <el-select
                  @change="changeTypet($event, i.navbar_component_icon_id)"
                  v-model="i.link_type"
                  style="width: 210px"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options2"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <!-- 自定义链接 -->
          <el-input
            v-if="i.link_type == 7"
            v-model="i.link"
            placeholder="请输入跳转链接 https://"
          ></el-input>
          <!-- 指定网校内容 -->
          <template
            v-if="
              i.link_type == '3' ||
              i.link_type == '4' ||
              i.link_type == '5' ||
              i.link_type == '6'
            "
          >
            <div class="zhiding" v-if="i.content_id != 0">
              <div class="name">
                [{{ i.content_type | getlinktype }}]{{ i.content_name }}
              </div>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                title="是否确定移除该内容"
                @confirm="remove(i.navbar_component_icon_id)"
              >
                <el-button slot="reference" type="text">移除</el-button>
              </el-popconfirm>
            </div>
            <el-button
              v-else
              type="text"
              @click="zhidingtype(i.navbar_component_icon_id)"
              style="margin-left: 124px; margin-top: 8px"
            >
              +点击选择内容
            </el-button>
          </template>
          <!-- 课程分类 -->
          <template v-if="i.link_type == '2'">
            <div class="fenlei" v-if="i.content_id">
              <div class="name">{{ i.content_name }}</div>
              <el-popconfirm
                confirm-button-text="确定"
                cancel-button-text="取消"
                icon="el-icon-info"
                icon-color="red"
                title="是否确定移除该内容"
                @confirm="remove(i.navbar_component_icon_id)"
              >
                <el-button slot="reference" type="text">移除</el-button>
              </el-popconfirm>
            </div>
            <el-button
              v-else
              type="text"
              @click="kechengtypeclick(i.navbar_component_icon_id)"
              style="margin-left: 124px; margin-top: 8px"
            >
              +点击选择分类
            </el-button>
          </template>
        </div>
        <div
          class="addicons"
          @click="optionlist.list.length >= 32 ? '' : addmore()"
          :style="optionlist.list.length >= 32 ? 'cursor: no-drop;' : ''"
        >
          +新增图标导航
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button style="width: 79px" class="mr20" @click="cancel" size="medium">
        取消
      </el-button>
      <el-button
        style="width: 79px"
        size="medium"
        type="primary"
        @click="submit"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'

export default {
  mixins: [selectdata],

  data() {
    return {
      visiblechangeval: '',
      selecticonid: '', //被选中的list列表中的id
      input: '',
      //图标的下拉选框
      options2: [
        {
          value: '1',
          label: '全部课程',
        },
        {
          value: '2',
          label: '课程分类',
        },
        {
          value: '3',
          label: '指定网校内容',
        },
        {
          value: '7',
          label: '自定义链接',
        },
      ],
      optionlist: _.merge({}, this.selectdata),
    }
  },
  filters: {
    getlinktype(val) {
      let text
      switch (Number(val)) {
        case 1:
          text = '资料'
          break
        case 2:
          text = '会员'
          break
        case 3:
          text = '作业'
          break
        default:
          text = '课程'
      }
      return text
    },
  },
  computed: {
    ...mapState('netSchoolDecration', [
      'selectdata',
      'iconslist',
      'isSelectPicture',
      'typeselectval',
      'singleselectcontentval',
    ]),
  },
  watch: {
    //图标库选择
    iconslist() {
      const index = this.optionlist.list.findIndex(
        (item) => item.navbar_component_icon_id == this.selecticonid
      )
      const val = _.assign({}, this.optionlist.list[index], {
        url: this.iconslist.url,
      })
      this.optionlist.list.splice(index, 1, val)
    },

    // 改变当前选中修改图像的值
    isSelectPicture() {
      const index = this.optionlist.list.findIndex(
        (item) => item.navbar_component_icon_id == this.selecticonid
      )
      const val = _.assign({}, this.optionlist.list[index], {
        url: this.isSelectPicture.url,
      })
      this.optionlist.list.splice(index, 1, val)
    },

    //改变课程分类的数据
    typeselectval() {
      const index = this.optionlist.list.findIndex(
        (item) => item.navbar_component_icon_id == this.selecticonid
      )
      const val = _.assign({}, this.optionlist.list[index], {
        content_id: this.typeselectval.set_id,
        content_name: this.typeselectval.set_name,
      })
      this.optionlist.list.splice(index, 1, val)
    },

    //改变指定网校内容
    singleselectcontentval() {
      const index = this.optionlist.list.findIndex(
        (item) => item.navbar_component_icon_id == this.selecticonid
      )
      const val = _.assign({}, this.optionlist.list[index], {
        content_id:
          this.singleselectcontentval.type == 1
            ? this.singleselectcontentval.ed_id
            : this.singleselectcontentval.type == 2
            ? this.singleselectcontentval.member_id
            : this.singleselectcontentval.type == 3
            ? this.singleselectcontentval.workbook_id
            : this.singleselectcontentval.course_id,
        content_name: this.singleselectcontentval.name,
        content_type: this.singleselectcontentval.type,
      })
      this.optionlist.list.splice(index, 1, val)
    },
  },

  methods: {
    ...mapMutations('netSchoolDecration', [
      'changgePicture',
      'kechengtype',
      'selectChange',
      'selecticon',
      'selectedChange',
      'selectdataChange',
    ]),

    visiblechange(val, val2) {
      if (val) {
        this.visiblechangeval = val2
      } else {
        this.visiblechangeval = ''
      }
    },

    //改变类型时，清空 content_id: '', content_name: '', content_type: '',三个数据
    changeTypet(val, val2) {
      const index = this.optionlist.list.findIndex(
        (item) => item.navbar_component_icon_id == val2
      )
      const val3 = _.assign({}, this.optionlist.list[index], {
        content_id: '',
        content_name: '',
        content_type: '',
      })
      this.optionlist.list.splice(index, 1, val3)
    },

    //移除数据
    remove(val) {
      const index = this.optionlist.list.findIndex(
        (item) => item.navbar_component_icon_id == val
      )
      const val3 = _.assign({}, this.optionlist.list[index], {
        content_id: '',
        content_name: '',
      })
      this.optionlist.list.splice(index, 1, val3)
    },

    // 课程分类
    kechengtypeclick(val) {
      this.selecticonid = val
      this.kechengtype(true)
    },

    //上移 下移
    upDownMove(index, newIndex) {
      let optionlist = this.optionlist.list
      const oldData = optionlist[newIndex]
      optionlist.splice(newIndex, 1, optionlist[index])
      optionlist.splice(index, 1, oldData)
    },

    //确定
    submit() {
      if (
        this.optionlist.list.findIndex((item) => item.url == '') > -1 &&
        this.optionlist.list.length > 0
      ) {
        this.$root.prompt('请选择图标！')
        return
      }
      if (
        this.optionlist.list.findIndex((item) => item.name == '') > -1 &&
        this.optionlist.list.length > 0
      ) {
        this.$root.prompt('请输入图标名称！')
        return
      }
      this.success()
    },

    // 取消
    cancel() {
      this.selectedChange('')
    },

    //删除
    confirm(val) {
      this.optionlist.list.splice(val, 1)
    },

    // 添加更多图片
    addmore() {
      this.optionlist.list.push({
        content_id: '',
        content_name: '',
        icon_sort_num: '',
        link: '',
        link_type: '',
        name: '',
        navbar_component_icon_id: Math.random(),
        navbar_component_id: '',
        url: '',
      })
      this.$nextTick(() => {
        this.$refs.list.scrollTop = this.$refs.list.scrollHeight
      })
    },

    //自定义图标
    custom(val) {
      this.selecticonid = val
      this.changgePicture(true)
    },

    //指定网校内容
    zhidingtype(val) {
      this.selecticonid = val
      this.selectChange(true)
    },

    //图标库选择
    selecticonku(val) {
      this.selecticonid = val
      this.selecticon(true)
      //   this.$store.commit('selecticon', true)
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #fff;
}
#iconNavigation {
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  .title {
    margin-bottom: 15px;
    font-size: 14px;
    color: #333333;
  }
  .main {
    overflow: auto;
    height: calc(100% - 39px);
  }
  .spacing {
    margin-bottom: 20px;
    .font1 {
      font-size: 14px;
      color: #333333;
      margin-bottom: 20px;
    }
    .setting {
      display: flex;

      .font2 {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-left: 12px;
      }
    }
  }
  .up_dw_dl {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 12px;
    color: #1b9d97;
    .driver {
      width: 1px;
      height: 12px;
      background-color: #1b9d97;
      margin: 0 10px;
    }
    span {
      cursor: pointer;
    }
  }
  .zhiding {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .name {
      width: calc(100% - 48px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      color: #333333;
      margin-right: 20px;
    }
    .update {
      cursor: pointer;
      font-size: 14px;
      color: #1b9d97;
    }
  }

  .icon_setting {
    .ico {
      margin-bottom: 10px;
      padding: 10px;
      background: #f5f5f5;
      border-radius: 2px;
      .fenlei {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          width: calc(100% - 54px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-right: 20px;
          font-size: 14px;
          color: #333333;
        }
        .remove {
          cursor: pointer;
          font-size: 14px;
          color: #1b9d97;
        }
      }
      .ico_contian {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        .el-dropdown {
          height: 48px;
        }
        .tu {
          margin-right: 20px;
          cursor: pointer;
          box-sizing: border-box;
          width: 46px;
          height: 46px;
          padding: 6px 11px;
          line-height: 18px;
          font-size: 12px;
          color: #ffffff;
          border-radius: 4px;
          position: relative;
          .hovershow {
            position: absolute;
            background-color: #0aa29b;
            display: none;
            color: #fff;
            z-index: 10;
            width: 46px;
            height: 46px;
            left: 0;
            top: 0;
            border-radius: 4px;
            text-align: center;
            padding-top: 5px;
            box-sizing: border-box;
          }
          .hovershow2 {
            display: block;
          }
          .noselect {
            color: #666666;
          }
          .selectoption {
            box-shadow: 0px 6px 20px 0px rgba(0, 0, 0, 0.15);
            border-radius: 2px;
            position: absolute;
            top: 49px;
            left: 0px;
            width: 230px;
            background-color: #fff;
            font-size: 14px;
            color: #333333;
            line-height: 14px;
            z-index: 100;
            p {
              height: 39px;
              padding: 15px 0 0 20px;
              box-sizing: border-box;
            }
          }
          img {
            width: 46px;
            height: 46px;
            position: absolute;
            left: 0;
            top: 0;
          }
        }
        .tu::after {
          content: '';
          height: 8px;
          width: 44px;
          z-index: 200;
          position: absolute;
          top: 43px;
          left: 1px;
        }
        .tu:hover .hovershow {
          display: block;
        }

        .right {
          .r_contian {
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            .name {
              width: 76px;
              font-size: 14px;
              color: #333333;
            }
          }
        }
      }
    }
    .addicons {
      cursor: pointer;
      padding: 26px 0;
      width: 100%;
      background: #f5f5f5;
      border-radius: 2px;
      font-size: 14px;
      color: #0aa29b;
      text-align: center;
    }
  }
  .btns {
    width: 100%;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px #f5f5f5 solid;
  }
}
</style>