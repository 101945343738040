<template>
  <div class="setting">
      <div
        v-if="index > 1 "
        class="icon"
        title="上移一层"
        @click="move({ oldIndex: index, newIndex: index - 1 })"
      >
        <img src="~@ass/img/1.4.1/icon_syyc.png" alt="" />
      </div>
      <div v-else class="icon" style="cursor: no-drop">
        <img src="~@ass/img/1.4.1/icon_syyc_hs.png" alt="" />
      </div>
      <div
        v-if="index < modulesCount - 2"
        class="icon"
        title="下移一层"
        @click="move({ oldIndex: index, newIndex: index + 1 })"
      >
        <img src="~@ass/img/1.4.1/icon_xyyc.png" alt="" />
      </div>
      <div v-else class="icon" style="cursor: no-drop">
        <img src="~@ass/img/1.4.1/icon_xyyc_hs.png" alt="" />
      </div>
    <div
      v-if="item.is_show == 1"
      class="icon"
      title="隐藏模块"
      @click="toggleShow({ index, status: 2 })"
    >
      <img src="~@ass/img/1.4.1/icon_ycmk.png" alt="" />
    </div>
    <div
      v-else
      class="icon"
      title="显示模块"
      @click="toggleShow({ index, status: 1 })"
    >
      <img src="~@ass/img/1.4.1/icon_xsmk.png" alt="" />
    </div>
    <div class="icon" @click="removeFn(index)" title="删除模块">
      <img src="~@ass/img/1.4.1/icon_ycmk@2x (1).png" alt="" />
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapMutations } = createNamespacedHelpers('netSchoolDecration')

export default {
  name: 'move',

  props: {
    index: Number,
    item: Object,
  },

  computed: {
    ...mapState({
      modulesCount: (state) => state.modules.length,
    }),
  },

  methods: {
    ...mapMutations(['move', 'toggleShow', 'remove', 'selectedChange']),

    removeFn(index) {
      this.$confirm('是否确定删除该模块！', '删除模块', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        this.remove(index)
        this.selectedChange()
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.setting {
  top: 0;
  text-align: center;
  width: 40px;
  position: absolute;
  background: #fbffff;
  border-radius: 4px;
  right: -60px;
  padding: 10px 0 5px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  .icon {
    box-sizing: border-box;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
      margin: 0 auto;
      width: 16px;
      height: auto;
    }
  }
}
</style>