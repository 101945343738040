<template>
  <div :class="`course-item${option.show_way}`">
    <template v-if="option.show_way == 1">
      <div class="cover">
        <img :src="item.photo" />
        <div class="course_type" v-if="option.is_show_label == 1">
          {{ item.course_type | getClassTypeName }}
        </div>
        <div class="tag">
          <div v-if="item.teamInfo.tc_price > 0" class="team-tag">
            <img src="~@ass/img/1.4.1/icon_ptbs@2x.png" alt="" />
          </div>
          <!-- <div v-if="item.fissionInfo.commission > 0" class="money-tag">
            <img src="~@ass/img/1.4.1/icon_zjjbs@2x.png" alt="" />
          </div> -->
        </div>
      </div>
      <div class="info">
        <div class="title" style="-webkit-box-orient: vertical">
          {{ item.name }}
        </div>
        <template v-if="item.teamInfo.tc_price > 0">
          <div class="price">
            <span class="pic">
              {{ item.teamInfo.tc_member }}人团·拼团价￥{{
                item.teamInfo.tc_price
              }}
            </span>
            <div class="vip" v-if="item.is_vip == 1">
              <img src="~@ass/img/1.4.1/icon_vip.svg" alt="" />
            </div>
          </div>
          <div class="detail" style="text-decoration: line-through">
            原价￥{{ item.price }}
          </div>
        </template>
        <template v-else-if="item.fissionInfo.commission > 0">
          <div class="price">
            <span class="pic">￥{{ item.price }}</span>
            <div class="vip" v-if="item.is_vip == 1">
              <img src="~@ass/img/1.4.1/icon_vip.svg" alt="" />
            </div>
          </div>
          <div class="detail">返 {{ item.fissionInfo.commission_ratio }}%</div>
        </template>
        <template v-else>
          <div class="price">
            <span class="pic">
              <template v-if="item.price_type == 1">
                ￥{{ item.price }}
              </template>
              <template v-if="item.price_type == 2">免费</template>
              <template v-if="item.price_type == 3">密码获取</template>
            </span>
            <span class="old-pic" v-if="item.costprice > 0">
              {{ item.costprice }}
            </span>
            <div class="vip" v-if="item.is_vip == 1">
              <img src="~@ass/img/1.4.1/icon_vip.svg" alt="" />
            </div>
          </div>
          <div class="detail" v-if="option.is_show_num == 1">
            共{{ item.count || 0 }}节
            <template v-if="item.is_show_sales == 1">
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              {{ item.number || 0 }}人学习
            </template>
          </div>
        </template>
      </div>
    </template>
    <template v-if="option.show_way == 2 || option.show_way == 3">
      <div class="cover">
        <img :src="item.photo" />
        <div class="course_type" v-if="option.is_show_label == 1">
          {{ item.course_type | getClassTypeName }}
        </div>
        <div class="tag">
          <div v-if="item.teamInfo.tc_price > 0" class="team-tag">
            <img src="~@ass/img/1.4.1/icon_ptbs@2x.png" alt="" />
          </div>
          <!-- <div v-if="item.fissionInfo.commission > 0" class="money-tag">
            <img src="~@ass/img/1.4.1/icon_zjjbs@2x.png" alt="" />
          </div> -->
        </div>
      </div>
      <div class="info">
        <div class="title" style="-webkit-box-orient: vertical">
          {{ item.name }}
        </div>
        <template v-if="item.teamInfo.tc_price > 0">
          <div class="price">
            <span class="pic">
              {{ item.teamInfo.tc_member }}人团·拼团价￥{{
                item.teamInfo.tc_price
              }}
            </span>
            <div class="vip" v-if="item.is_vip == 1">
              <img src="~@ass/img/1.4.1/icon_vip.svg" alt="" />
            </div>
          </div>
          <div class="detail" style="text-decoration: line-through">
            原价￥{{ item.price }}
          </div>
        </template>
        <template v-else-if="item.fissionInfo.commission > 0">
          <div class="price">
            <span class="pic">￥{{ item.price }}</span>
            <div class="vip" v-if="item.is_vip == 1">
              <img src="~@ass/img/1.4.1/icon_vip.svg" alt="" />
            </div>
          </div>
          <div class="detail">返 {{ item.fissionInfo.commission_ratio }}%</div>
        </template>
        <template v-else>
          <div class="price">
            <span class="pic">
              <template v-if="item.price_type == 1">
                ￥{{ item.price }}
              </template>
              <template v-if="item.price_type == 2">免费</template>
              <template v-if="item.price_type == 3">密码获取</template>
            </span>
            <span class="old-pic" v-if="item.costprice > 0">
              {{ item.costprice }}
            </span>
            <div class="vip" v-if="item.is_vip == 1">
              <img src="~@ass/img/1.4.1/icon_vip.svg" alt="" />
            </div>
          </div>
          <div class="detail" v-if="option.is_show_num == 1">
            共{{ item.count || 0 }}节
            <template v-if="item.is_show_sales == 1">
              &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
              {{ item.number || 0 }}人学习
            </template>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: 'CourseItem',

  props: {
    item: Object,
    option: {
      type: Object,
      default() {
        return {
          show_way: 1,
          is_show_num: 1,
          is_show_label: 1,
        }
      },
    },
    noJump: {
      type: Boolean,
      default: false,
    },
  },

  filters: {
    getClassTypeName(type) {
      let name
      switch (Number(type)) {
        case 1:
          name = '直播'
          break
        case 2:
          name = '小班'
          break
        case 3:
          name = '录播'
          break
        case 4:
          name = '系列课'
          break
      }
      return name
    },
  },
}
</script>
<style lang="scss" scoped>
@import './courseItem.scss';
</style>
