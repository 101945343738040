<template>
  <div class="search">
    <div class="content">
      <div class="icon"></div>
      <input
        v-model.trim="psw"
        maxlength="30"
        type="search"
        class="input"
        placeholder="输入课程名称搜索"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'search',

  props: {
    option: Object,
  },

  data() {
    return {
      psw: '',
    }
  },
}
</script>
<style lang="scss" scoped>
.search {
  background: #fff;
  padding: 0.5rem 0.7rem;
  .content {
    display: flex;
    height: 1.8rem;
    padding-left: 0.7rem;
    align-items: center;
    background: #f5f5f5;
    border-radius: 0.2rem;
    .icon {
      width: 0.8rem;
      height: 0.8rem;
      background: url('~@ass/img/1.4.1/icon_ss.svg') no-repeat center;
      background-size: 100%;
    }
    .input {
      flex: 1;
      border: 0;
      background: inherit;
      font-size: 0.7rem;
      padding-left: 0.5rem;
    }
  }
}
</style>