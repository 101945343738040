/**
 * 网校装修的公共js
 */

import { mapState, mapMutations } from 'vuex'

export default {
  data() {
    return {
      optionlist: {}
    }
  },

  computed: {
    ...mapState('netSchoolDecration', ['selectdata'])
  },

  watch: {

    selectdata: {
      handler(value) {
        if (this.optionlist.id && value.id === this.optionlist.id) {
          this.optionlist = _.merge({}, _.assign(
            {},
            value,
            this.optionlist,
            // 如果is_show 相等 说明改的是其他数据，新数据就要覆盖旧数据
            value.is_show == this.optionlist.is_show
              ? value
              : {
                is_show: value.is_show
              }
          ))
        } else {
          this.optionlist = _.merge({}, value)
          if (this.init) {
            this.init()
          }
        }
      },
      deep: true,
      immediate: true
    }
  },

  methods: {
    ...mapMutations('netSchoolDecration', ['selectedChange']),
    success() {
      this.saveDataSuccess()
      this.successPrompt()
    },

    saveDataSuccess() {
      this.selectdataChange(this.optionlist)
    },

    successPrompt() {
      this.$root.prompt({
        msg: '编辑成功，请点击右上角按钮发布到网校！',
        type: 'success'
      })
      this.selectedChange(this.optionlist.id)
    }
  }
}
