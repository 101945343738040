<template>
  <div id="navigation" :class="{ borderselected: false }">
    <div
      class="contain"
      v-for="(item, index) in navigatelist2"
      :key="item.id"
      :style="index == 0 ? 'color:red;background:#F5F5F5' : ''"
    >
      <img :src="item.icon" alt="" />
      <div class="title" :style="index == 0 ? 'color:#1B9D97' : ''">
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigation',
  data() {
    return {}
  },
  created() {},
  props: {
    navigate: Object,
  },
  computed: {
    navigatelist2() {
      return this.navigate.list.filter((item) => item.is_show == 1)
    },
  },
}
</script>

<style lang="scss" scoped>
#navigation {
  background-color: #fff;
  height: 49px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  .contain {
    flex: 1;
    img {
      width: 18px;
      height: auto;
      margin: 5px auto;
    }
    .title {
      font-size: 20px;
      transform: scale(0.5);
      white-space: nowrap;
      font-weight: 500;
      color: #888888;
      text-align: center;
    }
  }
}
</style>