<template>
  <div id="schoolInformation">
    <div class="main">
      <!-- 公共模块 -->
      <div class="spacing">
        <div class="font1">模块下方间距</div>
        <div class="setting">
          <el-switch
            v-model="optionlist.spacing_below"
            active-value="1"
            inactive-value="2"
          ></el-switch>
          <div class="font2">间距已开启</div>
        </div>
      </div>
      <div class="name">模块标题</div>
      <el-input
        type="text"
        placeholder="请输入内容"
        v-model="optionlist.name"
        maxlength="15"
        show-word-limit
        class="mb30"
      ></el-input>

      <div class="name">网校介绍对齐方式</div>
      <el-radio-group v-model="optionlist.align">
        <el-radio label="1">左对齐</el-radio>
        <el-radio label="2">居中对齐</el-radio>
        <el-radio label="3">右对齐</el-radio>
      </el-radio-group>

      <div class="name mt30">二维码</div>

      <!-- {{ optionlist.list }} -->
      <div class="erwema">
        <div class="left">
          <img
            :src="optionlist.list.jg_erweima"
            alt=""
            v-if="optionlist.list.jg_erweima"
          />
          <div class="nourl" v-else><span>未添加二维码</span></div>
        </div>
        <div class="right">
          <el-button @click="changgePicture(true)">选择图片</el-button>
          <div class="fontred" @click="del" v-if="optionlist.list.jg_erweima">
            删除图片
          </div>
        </div>
      </div>
    </div>
    <div class="btns">
      <el-button style="width: 79px" class="mr20" @click="cancel" size="medium">
        取消
      </el-button>
      <el-button
        style="width: 79px"
        size="medium"
        type="primary"
        @click="submit"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import selectdata from './selectdata'

export default {
  name: 'schoolInformation',

  mixins: [selectdata],

  data() {
    return {
      optionlist: _.merge({}, this.selectdata),
    }
  },

  computed: {
    ...mapState('netSchoolDecration', ['selectdata', 'isSelectPicture']),
  },

  watch: {
    isSelectPicture() {
      this.optionlist.list = _.assign({}, this.optionlist.list, {
        jg_erweima: this.isSelectPicture.url,
      })
    },
  },

  methods: {
    ...mapMutations('netSchoolDecration', [
      'selectdataChange',
      'selectedChange',
      'changgePicture',
    ]),
    //删除已选图片
    del() {
      this.optionlist.list = _.assign({}, this.optionlist.list, {
        jg_erweima: '',
      })
    },
    submit() {
      this.optionlist.name = this.optionlist.name.trim()

      this.success()
    },
    cancel() {
      this.selectedChange('')
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #fff;
}
#schoolInformation {
  height: 100%;
  .main {
    overflow: auto;
    height: calc(100% - 80px);
  }
  .spacing {
    margin-bottom: 20px;
    .font1 {
      font-size: 14px;
      color: #333333;
      margin-bottom: 20px;
    }
    .setting {
      display: flex;

      .font2 {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-left: 12px;
      }
    }
  }
  .name {
    margin-bottom: 10px;
    font-size: 14px;
    color: #333333;
  }
  .erwema {
    display: flex;
    .left {
      width: 137px;
      height: 137px;
      margin-right: 20px;

      .nourl {
        font-size: 15px;
        color: #999999;
        line-height: 15px;
        background-color: #f2f2f2;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      img {
        height: 100%;
        width: 100%;
      }
    }
    .right {
      .fontred {
        cursor: pointer;
        margin-top: 20px;
        font-size: 14px;
        color: #ff3535;
        line-height: 14px;
        text-align: center;
      }
    }
  }
  .el-radio {
    margin-right: 60px;
  }
  .el-radio:last-child {
    margin-right: 0;
  }

  .btns {
    width: 100%;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px #f5f5f5 solid;
  }
}
</style>