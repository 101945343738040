<template>
  <div class="work">
    <div class="title">
      <div class="text">{{ work.name }}</div>
    </div>
    <div class="course-list" v-if="work.list.length > 0">
      <!-- 大图 -->
      <template v-if="work.show_way == 2">
        <div class="contain" v-for="i in work.list" :key="i.open_class_id">
          <div class="cover">
            <img :src="i.cover_img" alt="" />
            <div class="teacher_contain">
              <div class="img">
                <img :src="i.uphoto" alt="" />
              </div>
              <div class="tname">{{ i.uname }}</div>
            </div>
          </div>
          <div class="opencourse_detail">
            <div class="course_name">{{ i.name }}</div>
            <div class="open_time" v-if="i.start_time > 0">
              {{ i.start_time | formatTimeStamp('YYYY-MM-dd hh:mm') }} 开播
            </div>
          </div>
        </div>
      </template>
      <!-- 列表 -->
      <template v-if="work.show_way == 1">
        <div class="listcontain" v-for="i in work.list" :key="i.open_class_id">
          <div class="cover">
            <img :src="i.cover_img" alt="" />
          </div>
          <div class="name_price">
            <div class="name">
              {{ i.name }}
            </div>
            <div class="price">
              {{
                i.play_type == 3
                  ? `￥${i.play_price}`
                  : i.play_type == 1
                  ? '免费'
                  : '密码获取'
              }}
            </div>
            <div class="time">
              {{ i.start_time | formatTimeStamp('YYYY-MM-dd hh:mm') }}开播
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'work',

  props: {
    work: Object,
  },
}
</script>
<style lang="scss" scoped>
.work {
  background: #fff;
  padding: 0 0 0.7rem;
  > .title {
    display: flex;
    align-items: center;
    .text {
      flex: 1;
      font-size: 0.8rem;
      font-weight: 600;
      color: #333333;
      position: relative;
      z-index: 3;
      padding: 1rem 0.7rem 0.7rem;
    }
    .more {
      font-size: 0.55rem;
      font-weight: 400;
      color: #999999;
      position: relative;
      padding: 20px 24px 20px 0;
      &:after {
        content: '';
        background: url('~@ass/img/1.4.1/ico_mckdj@2x.png');
        width: 6px;
        height: 9px;
        background-size: 100%;
        background-position: center;
        position: absolute;
        right: 0.7rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  img {
    width: 100%;
    height: 100%;
  }

  .listcontain {
    padding: 14px 20px;
    display: flex;
    border-bottom: #f5f5f5ff 1px solid;
    .cover {
      overflow: hidden;
      border-radius: 4px;
      img {
        width: 128px;
        height: 72px;
      }
      margin-right: 14px;
    }
    .name_price {
      width: calc(100% - 142px);
      .name {
        height: 32px;
        width: 100%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        font-size: 14px;
        font-weight: 600;
        color: #333333;
      }
      .price {
        font-size: 13px;
        font-weight: 500;
        color: #ff3535;
        margin: 8px 0;
      }
      .time {
        font-size: 10px;
        font-weight: 400;
        color: #999999;
      }
    }
  }

  .contain {
    margin: 0 14px;
    margin-bottom: 20px;
    height: 281px;
    background-color: #f5f5f5;
    border-radius: 5px;
    overflow: hidden;
    .cover {
      height: 197px;
      position: relative;
      .teacher_contain {
        position: absolute;
        display: flex;
        align-items: flex-end;
        bottom: -22px;
        left: 14px;
        .img {
          width: 40px;
          height: 40px;
          border-radius: 6px;
          border: 1px solid #ffffff;
          overflow: hidden;
        }
        .tname {
          font-size: 12px;
          margin-left: 10px;
          color: #333333;
        }
      }
    }
    .opencourse_detail {
      margin: 0 14px;
      .course_name {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        margin-top: 34px;
        margin-bottom: 10px;
      }
      .open_time {
        font-size: 12px;
        color: #666666;
      }
    }
  }
}
</style>