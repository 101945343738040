<template>
  <div class="schoolInfo">
    <div class="title">{{ jgInfo.name }}</div>
    <div
      class="jg-info"
      v-if="
        jgInfo.list.jg_logo ||
        jgInfo.list.title ||
        jgInfo.list.jg_introduce ||
        jgInfo.list.jg_erweima
      "
    >
      <img
        v-if="jgInfo.list.jg_logo"
        class="logo"
        :src="jgInfo.list.jg_logo"
        alt=""
      />
      <div v-if="jgInfo.list.title" class="name">{{ jgInfo.list.title }}</div>
      <div
        v-if="jgInfo.list.jg_introduce"
        class="jianjie"
        :class="`align${jgInfo.align}`"
      >
        {{ jgInfo.list.jg_introduce }}
      </div>
      <div v-if="jgInfo.list.jg_erweima" class="ewm">
        <img :src="jgInfo.list.jg_erweima" alt="" />
        <div class="text">长按识别二维码查看</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'schoolInfo',

  props: {
    jgInfo: Object,
  },
}
</script>
<style lang="scss" scoped>
.schoolInfo {
  background: #fff;
  .title {
    font-size: 0.8rem;
    font-weight: 600;
    color: #333333;
    padding: 1rem 0.7rem 0.7rem;
  }
  .jg-info {
    text-align: center;
    padding: 1rem 0.7rem 1.3rem;
    .logo {
      width: 100px;
      height: 100px;
      margin: 0 auto;
    }
    .name {
      font-size: 16px;
      font-weight: 600;
      color: #333333;
      margin-top: 1rem;
    }
    .jianjie {
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
      margin-top: 0.6rem;
      word-break: break-all;
      &.align1 {
        text-align: left;
      }
      &.align2 {
        text-align: center;
      }
      &.align3 {
        text-align: right;
      }
    }
    .ewm {
      margin-top: 1rem;
      img {
        width: 100px;
        height: 100px;
        margin: 0 auto;
      }
      .text {
        font-size: 13px;
        font-weight: 400;
        color: #666666;
        line-height: 13px;
        margin-top: 0.7rem;
      }
    }
  }
}
</style>