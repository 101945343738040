<template>
  <div id="modular">
    <div class="all">
      <div
        class="kuai"
        v-for="item in module"
        :key="item.type"
        @click="add(item)"
        :title="item.name"
        :style="item.noOpen ? 'cursor: no-drop' : ''"
      >
        <div class="icon">
          <img :src="item.icon" alt="" />
        </div>
        <div class="name" :class="{ is_no_open: item.noOpen }">
          {{ item.name }}
        </div>
        <div class="no_open" v-if="item.noOpen">未开通</div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapMutations, mapState } = createNamespacedHelpers('netSchoolDecration')

export default {
  data() {
    return {
      teacherlist: [], //获取教师列表
      schoollist: [],
    }
  },
  computed: {
    ...mapState(['selected']),

    module() {
      return [
        {
          type: 1,
          icon: require('@ass/img/1.4.1/icon_ss_mb.png'),
          name: '搜索栏',
          noOpen: false,
        },
        {
          type: 2,
          icon: require('@ass/img/1.4.1/icon_ggl_mb.png'),
          name: '轮播图',
          noOpen: false,
        },
        {
          type: 3,
          icon: require('@ass/img/1.4.1/icon_tbdh_mb.png'),
          name: '图标导航',
          noOpen: false,
        },
        {
          type: 4,
          icon: require('@ass/img/1.4.1/icon_kc_mb.png'),
          name: '课程',
          noOpen: false,
        },
        {
          type: 5,
          icon: require('@ass/img/1.4.1/icon_dzzl_mb.png'),
          name: '电子资料',
          noOpen: this.$store.state.userInfo.is_electronics == 2,
        },
        {
          type: 6,
          icon: require('@ass/img/1.4.1/icon_zyb_mb.png'),
          name: '公开课',
          noOpen: false,
        },
        {
          type: 10,
          icon: require('@ass/img/1.4.1/icon_sjzx_kcfl.svg'),
          name: '课程分类',
        },
        {
          type: 7,
          icon: require('@ass/img/1.4.1/icon_sztd_mb.png'),
          name: '师资团队',
          noOpen: false,
        },
        {
          type: 8,
          icon: require('@ass/img/1.4.1/icon_wxxx_mb.png'),
          name: '网校信息',
          noOpen: false,
        },
        {
          type: 11,
          icon: require('@ass/img/1.4.1/icon_wxxx_mb.png'),
          name: '图文',
          noOpen: false,
        },
      ]
    },
  },
  created() {
    this.getIntrInfo()
    this.getTeacherlist()
  },
  methods: {
    ...mapMutations(['addModule', 'selectedChange']),

    add({ noOpen, type }) {
      if (noOpen) return
      let val
      const id = Math.random()
      switch (type) {
        case 1:
        case 2:
        case 3:
        case 7:
        case 8:
          val = {
            is_show: '1',
            spacing_below: '1',
            id,
            data_type: type,
            align: '1',
            // type=3 / 9,图标导航需要用到
            show_way: '1',
          }

          // 除了搜索栏，其他的都要加list
          if (type != 1) {
            // 初始化网校信息
            if (type == 8) {
              val.align = '1'
              val.name = '网校信息'
              val.list = _.merge({}, this.schoollist)
            } else if (type == 7) {
              // 初始化教师列表
              val.name = '师资团队'
              val.list = _.merge([], this.teacherlist)
            } else {
              // 默认为空
              val.list = []
              val.name = ''
            }
          }
          break
        case 10:
          val = {
            is_show: '1',
            spacing_below: '1',
            id,
            name: '课程分类',
            data_type: type,
            align: '1',
            show_way: '1',
            list: [],
          }
          break
        case 4:
        case 5:
        case 11:
          val = {
            id,
            is_show: '1',
            name:
              type == 4
                ? '课程'
                : type == 5
                ? '电子资料'
                : type == 11
                ? '图文'
                : '作业',
            spacing_below: '1',
            show_num: 6,
            show_way: '1',
            is_show_num: '1',
            is_show_label: '1',
            is_show_more: '1',
            show_data_type: '1',
            sort_way: '1',
            data_type: type,
            list: [],
          }
          break

        case 6:
          val = {
            name: '公开课',
            is_show: '1',
            spacing_below: '1',
            show_data_type: '2',
            id,
            show_way: '1',
            data_type: type,
            list: [],
            show_num: 0,
          }
          break
      }
      const isSelected = this.selected
      this.addModule(val)
      this.selectedChange(id)
      try {
        if (isSelected) {
          this.$nextTick(() => {
            const offsetTop = document.querySelector('.cp.select').offsetTop
            document.documentElement.scrollTop =
              offsetTop - window.innerHeight / 2.5
          })
        } else {
          setTimeout(() => {
            document.documentElement.scrollTop = 99999999999
          }, 50)
        }
      } catch (e) {
        console.log(e)
      }
    },

    // 获取老师列表
    getTeacherlist() {
      this.$http({
        url: '/HomeTemplate/teacherList',
        data: {
          type: 1,
        },
        callback: ({ data }) => {
          this.teacherlist = data
        },
      })
    },

    // 获取机构信息
    getIntrInfo() {
      this.$http({
        url: '/HomeTemplate/intrInfo',
        callback: ({ data }) => {
          this.schoollist = data
        },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#modular {
  overflow: hidden;
  .is_no_open {
    color: #bbbbbb !important;
  }
  .all {
    width: 180px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .kuai {
    position: relative;
    cursor: pointer;
    width: 85px;
    height: 85px;
    background: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 10px;
    text-align: center;
    padding: 21px 0;
    box-sizing: border-box;
    &:hover {
      background: #e2e2e2;
    }
    .no_open {
      position: absolute;
      right: 0;
      top: 0;
      background: #ffcdcd;
      font-size: 12px;
      color: #ff3535;
      width: 46px;
      height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .icon {
      img {
        margin: 0 auto;
        height: 20px;
        width: 20px;
      }
    }
    .name {
      font-size: 12px;
      color: #666666;
      margin-top: 10px;
    }
  }
}
</style>
