<template>
  <div id="facultyTeam">
    <div class="maincontain">
      <!-- 公共模块 -->
      <div class="spacing">
        <div class="font1">模块下方间距</div>
        <div class="setting">
          <el-switch
            v-model="optionlist.spacing_below"
            active-value="1"
            inactive-value="2"
          ></el-switch>
          <div class="font2">间距已开启</div>
        </div>
      </div>
      <div class="title">模块标题</div>
      <el-input
        type="text"
        placeholder="请输入模块标题"
        v-model="optionlist.name"
        maxlength="15"
        show-word-limit
      ></el-input>
      <div class="title mt30">展示内容</div>
      <div class="main">
        <div
          class="contain"
          v-for="(item, index) in optionlist.list"
          :key="item.team_component_teacher_id"
        >
          <div class="left_teacher">
            <div class="tu">
              <img :src="item.uphoto" alt="" />
            </div>
            <div class="name">{{ item.uname }}</div>
          </div>
          <div class="btns">
            <span>
              <el-button
                type="text"
                @click="upDownMove(index, index - 1)"
                :disabled="index == 0"
              >
                上移
              </el-button>
            </span>
            <span class="division"></span>
            <span>
              <el-button
                type="text"
                :disabled="index == optionlist.list.length - 1"
                @click="upDownMove(index, index + 1)"
              >
                下移
              </el-button>
            </span>
            <span class="division"></span>
            <span>
              <el-button slot="reference" @click="confirm(index)" type="text">
                移除
              </el-button>
            </span>
          </div>
        </div>
        <div class="contain2" @click="addteacher">
          <div class="add_teacher">+添加老师</div>
        </div>
      </div>
    </div>

    <div class="btn">
      <el-button style="width: 79px" class="mr20" @click="cancel" size="medium">
        取消
      </el-button>
      <el-button
        style="width: 79px"
        size="medium"
        type="primary"
        @click="submit"
      >
        保存
      </el-button>
    </div>
  </div>
</template>

<script>
import selectdata from './selectdata'
import { mapMutations, mapState } from 'vuex'

export default {
  mixins: [selectdata],

  data() {
    return {
      value: '',
      text: '',
    }
  },
  computed: {
    ...mapState('netSchoolDecration', ['selectdata', 'selectedteacherlist']),
  },
  watch: {
    selectedteacherlist: {
      handler() {
        if (this.selectedteacherlist.length == 0) {
          return
        }
        this.optionlist.list.push(...this.selectedteacherlist)
        this.selectedteacherChange([])
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    ...mapMutations('netSchoolDecration', [
      'teacherSelect',
      'selectedChange',
      'selectdataChange',
      'selectedteacherChange',
    ]),
    addteacher() {
      const teacherids = this.optionlist.list.map(
        (item) => item.teacher_id || item.uid
      )
      const val = { isopen: teacherids }
      this.teacherSelect(val)
    },
    submit() {
      this.optionlist.name = this.optionlist.name.trim()
      this.success()
    },
    cancel() {
      this.selectedChange('')
    },
    //移除老师
    confirm(val) {
      this.optionlist.list.splice(val, 1)
    },
    //上移 下移
    upDownMove(index, newIndex) {
      let optionlist = this.optionlist.list
      const oldData = optionlist[newIndex]
      optionlist.splice(newIndex, 1, optionlist[index])
      optionlist.splice(index, 1, oldData)
    },
  },
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #fff;
}
#facultyTeam {
  height: 100%;
  overflow: auto;
  .maincontain {
    overflow: auto;
    height: calc(100% - 77px);
  }
  .spacing {
    margin-bottom: 20px;
    .font1 {
      font-size: 14px;
      color: #333333;
      margin-bottom: 20px;
    }
    .setting {
      display: flex;

      .font2 {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        margin-left: 12px;
      }
    }
  }
  .title {
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
  }
  .contain,
  .contain2 {
    padding: 10px;
    box-sizing: border-box;
    height: 50px;
    background: #f5f5f5;
    border-radius: 2px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left_teacher {
      display: flex;
      align-items: center;
      .tu {
        img {
          width: 30px;
          height: 30px;
          border-radius: 4px;
          margin-right: 10px;
        }
      }
      .name {
        font-size: 14px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      margin-right: 20px;
    }
    .btns {
      width: 115px;
      display: flex;
      align-items: center;
      font-size: 12px;
      color: #1b9d97;
      span {
        width: 50px;
        text-align: center;
        cursor: pointer;
      }
      .division {
        width: 1px;
        height: 12px;
        background-color: #1b9d97;
      }
    }
    .add_teacher {
      display: flex;
      align-items: center;
      margin: 0 auto;
      font-size: 14px;
      color: #1b9d97;
    }
  }

  .contain2 {
    cursor: pointer;
  }
  .btn {
    width: 100%;
    padding: 10px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px #f5f5f5 solid;
  }
}
</style>