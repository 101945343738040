<template>
  <div id="configure">
    <component class="contain" :is="componentName"></component>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// 课程参数设置
import courseSetting from '../editComponents/courseSetting'
//网校信息
import schoolInformation from '../editComponents/schoolInformation'
//底部导航
import navigated from '../editComponents/navigated'
//师资团队
import facultyTeam from '../editComponents/facultyTeam'
// 图标导航
import iconNavigation from '../editComponents/iconNavigation'
// 广告栏
import advertisement from '../editComponents/advertisement'
//搜索栏
import searchlan from '../editComponents/searchlan'
//作业
import workSetting from '../editComponents/workSetting'
//电子资料
import electronicSetting from '../editComponents/electronicSetting'
// 课程分类
import classificationSetting from '../editComponents/classificationSetting'

// 课程分类
import graphic from '../editComponents/graphic'

export default {
  components: {
    advertisement,
    iconNavigation,
    facultyTeam,
    navigated,
    schoolInformation,
    courseSetting,
    searchlan,
    workSetting,
    electronicSetting,
    classificationSetting,
    graphic,
  },

  computed: {
    ...mapState('netSchoolDecration', ['selectdata']),

    componentName() {
      let name
      switch (Number(this.selectdata.data_type)) {
        case 1:
          name = 'searchlan'
          break
        case 2:
          name = 'advertisement'
          break
        case 3:
          name = 'iconNavigation'
          break
        case 4:
          name = 'courseSetting'
          break
        case 5:
          name = 'electronicSetting'
          break
        case 6:
          name = 'workSetting'
          break
        case 7:
          name = 'facultyTeam'
          break
        case 8:
          name = 'schoolInformation'
          break
        case 9:
          name = 'navigated'
          break
        case 10:
          name = 'classificationSetting'
          break
        case 11:
          name = 'graphic'
          break
      }
      return name
    },
  },
}
</script>

<style lang="scss" scoped>
#configure {
  overflow: hidden;
  height: 100%;
  position: relative;
  box-shadow: -4px 0px 10px 0px rgba(0, 0, 0, 0.17);
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background-color: #fff;
  }
  .contain {
    padding: 20px;
  }
}
</style>
