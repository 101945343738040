<template>
  <div id="iconSelect">
    <el-dialog
      title="图标库选择"
      class="dialogVisible"
      width="800px"
      :visible="dialogstatus"
      @update:visible="(v) => $emit('update:dialogstatus', v)"
      v-if="dialogstatus"
      :close-on-click-modal="true"
      :destroy-on-close="true"
      @close="cancel"
    >
      <div class="dialogVisible_contain">
        <div
          class="icon"
          :class="{ selectborder: isselect == i.icon_id }"
          v-for="i in iconslist"
          :key="i.icon_id"
          @click="isSelect(i)"
        >
          <img :src="i.url" alt="" />
          <div class="selecticon" v-if="isselect == i.icon_id">
            <img src="~@ass/img/1.4.1/icon_xztb@2x.png" alt="" />
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="sure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //图标列表的获取
      iconslist: [],
      //被选中图标的索引
      isselect: '',
      dialogVisible: false,
      //被选中的图标的shuju
      selected: {},
    }
  },
  created() {
    this.getdata()
  },
  props: {
    dialogstatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getdata() {
      this.$http({
        url: '/HomeTemplate/iconList',
        callback: ({ code, data }) => {
          if (code == 200) {
            this.iconslist = data
          }
        },
      })
    },
    isSelect(val) {
      this.isselect = val.icon_id
      this.selected = val
    },
    cancel() {
      // 取消后回复选择数据原样
      this.$emit('close')
    },
    sure() {
      this.$emit('complete', this.selected)
      this.cancel()
    },
  },
}
</script>

<style lang="scss">
#iconSelect {
  .dialogVisible_contain {
    display: flex;
    flex-wrap: wrap;
    .selectborder {
      border: 1px solid #1b9d97 !important;
    }
    .icon {
      position: relative;
      cursor: pointer;
      width: 68px;
      height: 68px;
      padding: 10px;
      box-sizing: border-box;
      background: #ffffff;
      border: 1px solid #e8e8e8;
      margin-right: 8.8px;
      margin-bottom: 9px;
      img {
        width: 48px;
        height: 48px;
      }
      .selecticon {
        position: absolute;
        right: 0;
        bottom: 0;
        img {
          width: 12px;
          height: auto;
        }
      }
    }
    .icon:nth-child(10n) {
      margin-right: 0;
    }
  }
}
</style>