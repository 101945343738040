<template>
  <div id="classification">
    <!-- {{ classification }} -->
    <div class="title">
      <div class="text">{{ classification.name }}</div>
    </div>
    <div class="list">
      <div class="contain" v-for="i in classification.list" :key="i.set_id">
        <div class="tu">
          <img
            :src="i.icon"
            :style="classification.show_way == 2 ? 'border-radius:50%' : ''"
            alt=""
          />
        </div>
        <div class="name" :title="i.set_name">{{ i.set_name }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'classification',
  props: {
    classification: Object,
  },
}
</script>

<style lang="scss" scoped>
#classification {
  background-color: #fff;
  img {
    width: 100%;
    height: 100%;
  }
  .title {
    display: flex;
    align-items: center;
    .text {
      flex: 1;
      font-size: 0.8rem;
      font-weight: 600;
      color: #333333;
      position: relative;
      z-index: 3;
      padding: 1rem 0.7rem 0.7rem;
    }
  }
  .list {
    padding: 0 4px;
    display: flex;
    flex-wrap: wrap;
    .contain {
      width: 66px;
      margin: 3px;
      margin-bottom: 30px;
      .tu {
        margin: 0 auto;
        width: 32px;
        height: 32px;
        border-radius: 4px;
      }
      .name {
        margin-top: 14px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        word-break: break-all;
        font-size: 11px;
        font-weight: 400;
        color: #333333;
      }
    }
  }
}
</style>